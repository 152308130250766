import React, { useState, useEffect } from "react";
import axios from "axios";

import './CardImageIn.css';
import SeparatorBar from "../../SeparatorBar/SeparatorBar";

const CardImageIn = () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiImg = process.env.REACT_APP_API_IMG;

  const [randomPhone, setRandomPhone] = useState(null);

  useEffect(() => {
    fetchRandomPhone();
  }, []);

  const fetchRandomPhone = async () => {
    try {
      const response = await axios.get(`${apiUrl}/random`);
      setRandomPhone(response.data);
    } catch (error) {
      console.error("Erro ao buscar o rural aleatório:", error);
    }
  };
  return (
    <>
      <div className="row">
        {randomPhone && (
          <>
            <div className="assinar">
              <img src={`${apiImg}/assets/phones/${randomPhone.foto}`} alt="call center" />
            </div>
          </>
        )}
      </div>
    </>
  )
}
export default CardImageIn;