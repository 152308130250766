import React from "react";

import './styles.css';
import CarouselSlideTV from "../../components/ComponetTV/CarouselSlideTV";

// import CardOffer from "../../components/CardOffer";
import GigabyteHDTVFaq from "./GigabyteHDTVFaq/GigabyteHDTVFaq";
import InfiniteSlider from "../../components/InfiniteSlider/InfiniteSlider";
import AnimatedCard from "../../components/AnimatedCard";
import { useMediaQuery } from 'react-responsive';
import SeparatorBar from "../../components/SeparatorBar/SeparatorBar";
import CardTV from "../../components/ComponetTV/CardTV/CardTV";
import AppGigaPlay from "../../components/ComponetTV/AppGigaPlay/AppGigaPlay";
import FotoSlideTv from "../../components/FotoSlideTv/FotoSlideTv";

const Tv = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <>
            {isMobile ? <FotoSlideTv /> : <CarouselSlideTV />}
            <div className="container">
                <CardTV />
            </div>
            {/* <AnimatedCard /> */}
            <SeparatorBar />
            <AppGigaPlay />
            <SeparatorBar />
            <InfiniteSlider />
            <div className="container">
                <GigabyteHDTVFaq />
            </div>
        </>
    )
}
export default Tv;