import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // Você pode usar localStorage se preferir
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CookiesPage from '../../pages/Politicas/CookiesPage';

const CookieConsent = ({ onConsentGiven }) => {
  const [showConsent, setShowConsent] = useState(false);

  // Estados para controlar os modal
  const [showCookiesModal, setShowCookiesModal] = useState(false);

  const handleOpenCookiesModal = () => setShowCookiesModal(true);
  const handleCloseCookiesModal = () => setShowCookiesModal(false);

  useEffect(() => {
    // Verifica se o cookie de consentimento já foi aceito
    const consent = Cookies.get('cookie_consent'); // ou localStorage.getItem('cookie_consent');
    if (!consent) {
      setShowConsent(true); // Mostra o aviso de cookies se o consentimento não foi dado
    } else {
      onConsentGiven(true); // Informa ao NavBar que o consentimento foi dado
    }
  }, [onConsentGiven]);

  const acceptCookies = () => {
    // Armazena o consentimento por 1 ano
    Cookies.set('cookie_consent', 'accepted', { expires: 30 });
    setShowConsent(false); // Esconde o aviso após o consentimento
    onConsentGiven(true); // Notifica o NavBar sobre o consentimento
  };

  const rejectCookies = () => {
    Cookies.set('cookie_consent', 'rejected', { expires: 7 });
    setShowConsent(false);
    onConsentGiven(false);
  };

  if (!showConsent) return null;

  return (
    <>
      <div style={styles.cookieBanner}>
        <p>
          Este site usa cookies para garantir que você obtenha a melhor experiência em nosso site.{' '}
          <span style={styles.span} onClick={handleOpenCookiesModal}>
            Leia Nossa Políticas de Cookies
          </span>
        </p>
        <button onClick={acceptCookies} style={styles.acceptButton}>Aceitar</button>
        <button onClick={rejectCookies} style={styles.rejectButton}>Rejeitar</button>
      </div>
      <Modal show={showCookiesModal} onHide={handleCloseCookiesModal}>
        <Modal.Header closeButton>
          <Modal.Title>Política de Cookies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CookiesPage />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCookiesModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const styles = {
  cookieBanner: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#333',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
    zIndex: 1000,
  },
  acceptButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
    marginLeft: '10px',
    borderRadius: '5px',
  },
  rejectButton: {
    backgroundColor: '#f44336',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
    marginLeft: '10px',
    borderRadius: '5px',
  },
  span: {
    cursor: 'pointer',
    color: 'green',
    textDecoration: 'none'
  }
};

export default CookieConsent;
