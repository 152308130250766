import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Google from '../../assets/icons/google-maps.png';
import './Cidades.css';

const cities = [
  {
    name: 'Carmo Do Rio Verde',
    address: 'Endereço 1',
    phone: '0800 062-2200',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'Ceres',
    address: 'Endereço 2',
    phone: '(62) 2201-0000',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'Goiânia',
    address: 'Endereço 3',
    phone: '(62) 2203-0000',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'Aparecida De Goiânia',
    address: 'Endereço 4',
    phone: '0800 062-2200',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'Jaraguá',
    address: 'Endereço 5',
    phone: '(62) 2200-0000',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'Rialma',
    address: 'Endereço 6',
    phone: 'Rialma (62) 2201-0000',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'Rianápolis',
    address: 'Endereço 7',
    phone: '0800 062-2200',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'Uruana',
    address: 'Endereço 8',
    phone: '0800 062-2200',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'Uruaçu',
    address: 'Endereço 9',
    phone: '(62) 2207-0000',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'Uruceres',
    address: 'Endereço 10',
    phone: '0800 062-2200',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'Braslândia',
    address: 'Endereço 11',
    phone: '0800 062-2200',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'Nova Gloria',
    address: 'Endereço 12',
    phone: '0800 062-2200',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
  {
    name: 'São Francisco',
    address: 'Endereço 13',
    phone: '0800 062-2200',
    contact: 'contato@gigabytetelecom.com.br',
    image: 'https://mockupphotoscdn.azureedge.net/product/dimitri-tyan-232294_180929141040.jpg'
  },
];


const Cidades = () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiImg = process.env.REACT_APP_API_IMG;

  const [cidades, setCidades] = useState([]);

  const TextRender = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  useEffect(() => {
    fetchCidades();
  }, []);

  const fetchCidades = async () => {
    try {
      const response = await axios.get(`${apiUrl}/cidades`);
      setCidades(response.data);
    } catch (error) {
      console.error("Erro ao buscar os cidades:", error);
    }
  };


  return (
    <div className="cidades-container">
      <div className='container'>
        <h1 className="cidades-title">Nossas Lojas</h1>
        <span>🙂 Precisou de atendimento presencial?
          A Gigabyte tem varias filiais que fica bem pertinho de você, com equipe capacitada para resolver seu problema!
          Mas lembrando que você também nos encontra através do 0800 062-2200 com atendimento de domingo a domingo para te ajudar.🤝
        </span><br /><br />
      </div>
      <div className="cidades-grid">
        <Container>
          <Row>
            {cidades.map((cidade, index) => (
              <Col key={index} md={3} sm={6}>
                <Card className="mb-4">
                  <Card.Img variant="top" src={`${apiImg}/assets/cidades/${cidade.foto}`} alt={cidade.nome} />
                  <Card.Body>
                    <Card.Title>{cidade.nome}</Card.Title>
                    <Card.Text>
                      <div className='cidade-info'>
                        <strong><img src={Google} alt="google-icon" /></strong> {cidade.endereco} <br />
                        <strong><i class="fa fa-phone-square" aria-hidden="true"></i> </strong> {cidade.telefone} <br />
                        <strong><i class="fa fa-clock-o" aria-hidden="true"></i> Atendimento: </strong> {cidade.contato}
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Cidades;
