import React from "react";
import './styles.css';

const FlipBox = () => {
  return (
    <div className="container">
      <div className='title-app'>
        <h3>Nossos Canais de Atendimento</h3>
        <p>Estamos sempre preparados para lhe atender da melhor forma! Confira alguns meios de contato. </p>
      </div>
      <div class="services-box">
        <div class="service">
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="flip-box-front">
                <h1><i className="fa fa-phone-square " aria-hidden="true"> </i></h1>
                62 2200 0000 ou 0800 062 2200
                <p>Atendimento via Telefone</p>
              </div>
              <div class="flip-box-back">
                <h1><i className="fa fa-phone-square " aria-hidden="true"> </i></h1>
                <p>Converse diretamente conosco! Estamos aqui para ajudar no que você precisar. Ligue agora para nosso Atendimento via Telefone e receba suporte personalizado.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="service">
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="flip-box-front">
                <h1><i className="fa fa-whatsapp" aria-hidden="true"> </i></h1>
                62 2200 0000
                <p>Atendimento via Whatsapp</p>
              </div>
              <div class="flip-box-back">
                <h1><i className="fa fa-whatsapp" aria-hidden="true"> </i></h1>
                <p>
                  Tenha suporte imediato via WhatsApp! Entre em contato conosco agora mesmo para resolver suas questões de forma rápida e eficiente.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="service">
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="flip-box-front">
                <h1><i className="fa fa-envelope" aria-hidden="true"> </i></h1>
                gigabyte@gigabytetelecom.com.br
                <p>Atendimento via E-mail</p>
              </div>
              <div class="flip-box-back">
                <h1><i className="fa fa-envelope" aria-hidden="true"> </i></h1>
                <p>Entre em contato conosco por e-mail para receber um atendimento personalizado e detalhado. Estamos prontos para responder às suas dúvidas e necessidades.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="service">
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="flip-box-front">
                <h1><i className="fa fa-money" aria-hidden="true"> </i></h1>
                Central de Atendimento
                <p>Pagamento via Pix</p>
              </div>
              <div class="flip-box-back">
                <h1><i className="fa fa-money" aria-hidden="true"> </i></h1>
                <p>Facilite seus pagamentos com Pix! Rápido, seguro e sem complicação. Utilize o Pix para pagar suas faturas de forma ágil e eficiente.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="service">
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="flip-box-front">
                <h1><i className="fa fa-question-circle-o" aria-hidden="true"> </i></h1>
                Dúvidas e perguntas frequentes
                <p>Central do Assinante</p>
              </div>
              <div class="flip-box-back">
                <h1><i className="fa fa-question-circle-o" aria-hidden="true"> </i></h1>
                <p>Explore a nossa Central do Assinante para obter informações detalhadas e recursos exclusivos.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="service">
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="flip-box-front">
                <h1><i className="fa fa-phone-square " aria-hidden="true"> </i></h1>
                Um de nossos consultores ligará para você
                <p>Te Ligamos!</p>
              </div>
              <div class="flip-box-back">
                <h1><i className="fa fa-phone-square " aria-hidden="true"> </i></h1>
                <p>Deixe-nos ligar para você! Um de nossos consultores entrará em contato em breve.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlipBox;
