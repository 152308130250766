import NotFound from '../../assets/images/404.png';

import './styles.css';

const NotFoundPage = () => {
  return (
    <div className='container'>
      <div className="erro">
        <img src={NotFound} alt="" />
      </div>
    </div>
  );
}

export default NotFoundPage;