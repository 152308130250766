import React, { useEffect, useState } from 'react';
import { jarallax, jarallaxVideo } from 'jarallax';
import axios from 'axios';

import './styles.css';

jarallaxVideo();

const ParallaxSite = () => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiImg = process.env.REACT_APP_API_IMG;

    const [tvs, setTvs] = useState([]);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        // Função assíncrona para buscar os vídeos do servidor
        const fetchTvs = async () => {
            try {
                await delay(2000); // Atraso de 2 segundos
                const response = await axios.get(`${apiUrl}/tvs`);
                // Configurar os vídeos na resposta no estado
                setTvs(response.data);
            } catch (error) {
                console.error('Erro ao buscar vídeos:', error);
            }
        };

        // Chamar a função para buscar vídeos
        fetchTvs();
    }, []); // A dependência vazia significa que isso só acontecerá uma vez ao montar o componente

    useEffect(() => {
        // Inicializa o plugin Jarallax e define a altura desejada
        jarallax(document.querySelectorAll('.jarallax'), {
            // Defina a altura personalizada aqui (por exemplo, '300px')
            height: '400px',
        });
    }, [tvs]); // Atualiza o Jarallax sempre que a lista de vídeos mudar

    const randomIndex = Math.floor(Math.random() * tvs.length);

    return (
        <>
            {tvs && tvs.length > 0 && (
                <div
                    key={tvs[randomIndex]._id}
                    className="jarallax"
                    data-video-src={`mp4:${apiImg}/assets/tvs/${tvs[randomIndex].video}`}
                    data-speed="1"
                >
                    <div className='assinar'>
                        <h1 className='tv'>Assista os melhores canais de TV por assinatura</h1>
                        <div className="buttons">

                            <div className="buttons">
                                <a href="/tvs" className="btn-hover color-8">Escolha seu plano!</a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ParallaxSite;
