
import React, { useState, useEffect } from "react";
import axios from 'axios';

const CopywritingPhone = () => {
  const [copywritingsPhones, setCopywritings] = useState([]);

  const TextRender = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  useEffect(() => {
    fetchCopywritings();
  }, []);

  const fetchCopywritings = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/copywritingsPhone`);
      setCopywritings(response.data);
    } catch (error) {
      console.error("Erro ao buscar os CopywritingPhone:", error);
    }
  };

  return (
    <>
      {/* <h1 className="mb-4">Telefonia Fixa via Internet</h1>
              <p>
                Oferecemos serviços de telefonia fixa através da internet, proporcionando flexibilidade e economia.
                Se você possui uma conexão de internet, pode contratar nossos serviços.
              </p> */}

      {copywritingsPhones.map((copywritingPhone) => (
        <div key={copywritingPhone._id}>
          <h1 className="mb-4">{copywritingPhone.title}</h1>
          <h5>{copywritingPhone.sub}</h5>
          <p><TextRender htmlContent={copywritingPhone.text} /></p>          
        </div>

      ))}

    </>
  )
}
export default CopywritingPhone;