import React from 'react';
import { Container, Accordion } from 'react-bootstrap';
import './GigabyteHDTVFaq.css'; // Importando o arquivo de estilos

const GigabyteHDTVFaq = () => {
  return (
    <Container className="faq my-5">
      <div className="text-center-tv mb-4">Dúvidas Frequentes - Gigabyte HDTV</div>
      <Accordion className='text-accordion'>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            A Gigabyte HDTV altera seu plano de TV por Assinatura sem o seu consentimento?
          </Accordion.Header>
          <Accordion.Body>
            Não, pois qualquer alteração no plano de serviço deve ser informada, no mínimo, 30 dias antes de sua implementação. A informação da alteração de algum canal será realizada por meio do canal 11 que é disponibilizado em qualquer pacote.
            <br />
            <strong>Fundamentação Legal:</strong> Art. 52 da Resolução nº 632/2014 da Anatel e Art. 28 da Resolução nº 488/2007 da Anatel.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Posso solicitar a suspensão dos serviços de TV por Assinatura?
          </Accordion.Header>
          <Accordion.Body>
            Sim. Você pode cancelar qualquer serviço a qualquer momento.
            <br />
            <strong>Fundamentação Legal:</strong> Art. 3º, XIV da Resolução nº 632/2014 da Anatel e Arts. 3º, XXII e 12 da Resolução nº 488/2007 da Anatel.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Qual o prazo para restabelecimento dos serviços de TV por assinatura em caso de interrupção?
          </Accordion.Header>
          <Accordion.Body>
            O restabelecimento do serviço deve ocorrer em até 48 horas, salvo em caso de força maior.
            <br />
            <strong>Fundamentação Legal:</strong> Art. 16, §§ 1º e 2º da Resolução nº 411/2005 da Anatel.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            Qual o prazo que a prestadora tem para instalar o serviço de TV por assinatura?
          </Accordion.Header>
          <Accordion.Body>
            O prazo de instalação é acordado entre você e a empresa e não poderá atrasar mais que 15 dias em relação à data marcada, somente o titulor ou a partir dos 18 anos.
            <br />
            <strong>Fundamentação Legal:</strong> Art. 9º, § 1º da Resolução nº 411/2005 da Anatel.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Qual é o prazo que a prestadora tem para atender solicitações de reparo por falhas ou defeitos na prestação de serviços de TV por assinatura?
          </Accordion.Header>
          <Accordion.Body>
            Até 48 horas, a partir da solicitação do consumidor. Mais Gigabyte HDTV tem como uma de suas prioridades atender o cliente no mesmo dia da sua solicitação.
            <br />
            <strong>Fundamentação Legal:</strong> Art. 17, § 1º da Resolução nº 411/2005 da Anatel.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            Como é feita a instalação dos serviços adquiridos?
          </Accordion.Header>
          <Accordion.Body>
            A instalação é feita por um técnico especializado que agenda sua visita e realiza todos os procedimentos de instalação e testes do serviço. No processo de instalação é necessária a presença do solicitante da linha que ira assinar o contrato e receber todas as informações referente a sua TV.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            Somente o titular da assinatura pode receber o técnico em caso de solicitação de suporte?
          </Accordion.Header>
          <Accordion.Body>
            Não, qualquer pessoa com mais de 18 anos que possa representar o titular e receber o técnico relatando o problema.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            É possível bloquear canais do meu pacote?
          </Accordion.Header>
          <Accordion.Body>
            Sim, você pode bloquear os canais considerados impróprios através do próprio aparelho Set top - box. Siga as instruções no seu televisor e garanta que ninguém assista programas não produzidos para sua faixa etária. Caso tenha duvidas de como fazer esse procedimento, ligue para a central de atendimento.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            Eu preciso ter algum equipamento para instalar a TV por assinatura da Gigabyte HDTV?
          </Accordion.Header>
          <Accordion.Body>
            Não, todo o material e equipamento necessários para a instalação do serviço de TV por assinatura é fornecido pela empresa em sistema de comodato. Porem sua TV deve ser compatível com esses equipamentos, onde dever ter entrada HDMI.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default GigabyteHDTVFaq;
