import React, { useState, useEffect } from "react";
import axios from 'axios';
import TV from '../../../../assets/images/informa.png';

import './ComboHeader.css';

const ComboHearder = () => {
  // const [foto, setFoto] = useState(null); // Foto atual selecionada
  const [fotos, setFotos] = useState([]); // Fotos cadastradas

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiImg = process.env.REACT_APP_API_IMG; // Usado para visualizar a imagem cadastrada

  // Fetch de fotos cadastradas
  const fetchFotos = async () => {
    try {
      const response = await axios.get(`${apiUrl}/fotos_combo`);
      setFotos(response.data);
    } catch (error) {
      console.error('Erro ao buscar as fotos:', error);
    }
  };
  useEffect(() => {
    fetchFotos();
}, [apiUrl]);

  return (
    <>
      <div className='combo-header'>
        {fotos.map((foto) => (
          <div key={foto._id} className="foto-item">
            <img
              src={`${apiImg}/assets/combos/${foto.foto}`}
              alt="Foto cadastrada"
              style={{
                width: '100%',
                maxHeight: '200px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}
            />
          </div>
        ))}
      </div>
    </>
  )
}
export default ComboHearder;