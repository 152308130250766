import React, { useState, useEffect } from "react";
import axios from "axios";
import Farm from "../../../assets/images/farm2.png";
import Button from "react-bootstrap/Button";

import './styles.css';
import RuralInternet from "../RuralInternet/RuralInternet";
import RuralInternetPlan from "../RuralInternetPlan/RuralInternetPlan";

const InternetRural = () => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiImg = process.env.REACT_APP_API_IMG;

    const [randomRural, setRandomRural] = useState(null);

    useEffect(() => {
        fetchRandomRural();
    }, []);

    const fetchRandomRural = async () => {
        try {
            const response = await axios.get(`${apiUrl}/random_rural`);
            setRandomRural(response.data);
        } catch (error) {
            console.error("Erro ao buscar o rural aleatório:", error);
        }
    };

    return (
        <>
            <div className="container-fluid rural">

                <div className="row">
                    {randomRural && (
                        <>
                            <div
                                className="assinar"
                                style={{
                                    backgroundImage: `url(${apiImg}/assets/rurais/${randomRural.foto})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    with: '100%',
                                    height: '500px',
                                }}
                            >
                                <div className="title-rural">
                                    <div className="title-rural-img">
                                        <h1><img src={Farm} alt="call center" /> {randomRural.title}</h1>
                                    </div>
                                    <div className="sub-rural">
                                        <h3>{randomRural.sub}</h3>
                                    </div>
                                </div>
                                

                                {/* <div className="buttons">
                                    <button className="btn-hover color-6">Contratar agora!</button>
                                </div> */}

                            </div>
                            <div className="text" dangerouslySetInnerHTML={{ __html: randomRural.text }} />
                        </>
                    )}
                    {/* {randomRural && Object.keys(randomRural).length > 0 &&
                        <span> * Valor promocional válido somente para pagamentos até a data do vencimento. </span>
                    } */}
                </div>
                <br />
                <br />
            </div>
            <RuralInternetPlan />
            <RuralInternet />
        </>
    );
}

export default InternetRural;
