import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Container, Row, Col } from 'react-bootstrap';
import Google from '../../assets/images/googleplay.png';
import AppleStore from '../../assets/images/AppStore.png';
import TvIcon from '../../assets/icons/Icontv.png';

import './styles.css'
import ParallaxSite from "../ParallaxSite";

const CardHome = () => {

    // const apiUrl = process.env.REACT_APP_API_URL;
    // const apiImg = process.env.REACT_APP_API_IMG;

    // const [cards, setCards] = useState([]);

    // const TextRender = ({ htmlContent }) => {
    //     return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    // };

    // useEffect(() => {
    //     fetchCards();
    // }, []);

    // const fetchCards = async () => {
    //     try {
    //         const response = await axios.get(`${apiUrl}/cards`);
    //         setCards(response.data);
    //     } catch (error) {
    //         console.error("Erro ao buscar os cards:", error);
    //     }
    // };

    return (
        <>
            <Container fluid>
                <Row className="my-row-content-top">
                    {/* {cards && Object.keys(cards).length > 0 && */}
                        <>
                            <Col xs={12}>
                                <h1><img src={TvIcon} alt="" /> Gigabyte HD TV</h1>
                                <h3>Tenha acesso HD TV de onde você estiver </h3>
                                <p>Baixe nossos aplicativos Gigabyte Play e acesse seus canais preferidos na palma da sua mão</p>
                            </Col>
                            <Col xs={12} className="my-row-content-top-img">
                                <a href="https://play.google.com/store/apps/details?id=com.gigabytemobile&hl=pt_BR&pli=1" target="_blank" rel="noopener noreferrer">
                                    <img src={Google} alt="" />
                                </a>
                                <a href="https://apps.apple.com/br/app/gigabyte-play/id6443457059?l=pt" target="_blank" rel="noopener noreferrer">
                                    <img src={AppleStore} alt="" />
                                </a>
                            </Col>
                        </>
                    {/* } */}
                </Row>
                <Row>
                    <ParallaxSite />
                </Row>
            </Container>
        </>
    );

}

export default CardHome;