// CategoryTabs.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Tabs, Tab } from "react-bootstrap";
import ProductList from "../ProductList/ProductList";
import "./CategoryTabs.css";

const CategoryTabs = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
      setCategories(response.data);
      setSelectedCategory(response.data[0]?._id); // Define a primeira categoria como padrão
    } catch (error) {
      console.error("Erro ao buscar as categorias:", error);
    }
  };

  const handleTabSelect = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  return (
    <div className="category-tabs">
      <h3>Aparelhos Homologados </h3>
      <Tabs
        activeKey={selectedCategory}
        onSelect={(categoryId) => handleTabSelect(categoryId)}
        id="category-tabs"
        className="mb-3"
      >
        {categories.map((category) => (
          <Tab eventKey={category._id} title={category.name} key={category._id}>
            <ProductList categoryId={category._id} categoryName={category.name} />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default CategoryTabs;
