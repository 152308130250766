import FotoTv from '../../assets/images/fotoTvMovel.jpg';

import './FotoSlideTv.css';

const FotoSlideTv = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='foto-tv'>
            <img src={FotoTv} alt="foto-tv" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default FotoSlideTv;