import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import witchImage from '../../assets/gifs/gif-de-natal-destaque.gif'; // Certifique-se de que o caminho está correto

const Christmas = () => {
  const [showWitch, setShowWitch] = useState(false);
  const [reset, setReset] = useState(false);

  const props = useSpring({
    from: { left: '100vw' },  // Começa fora da tela à direita
    to: { left: '-200px' },   // Termina fora da tela à esquerda
    reset: reset,
    onRest: () => setReset(false),
    config: { duration: 10000 }, // Duração de 3 segundos para um voo mais rápido
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setShowWitch(true);
      setReset(true); // Reinicia a animação
      setTimeout(() => {
        setShowWitch(false);
      }, 7000); // A bruxinha desaparece após 3 segundos
    }, 18000); // Reaparece a cada 8 segundos

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {showWitch && (
        <animated.div
          style={{
            position: 'fixed',
            top: '20%',
            zIndex: 9999,
            ...props, // Animação no estilo
          }}
        >
          <img src={witchImage} alt="Bruxa voando" style={{ width: '150px' }} />
        </animated.div>
      )}
    </>
  );
};

export default Christmas;
