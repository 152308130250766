import React, { useState, useEffect } from "react";
import axios from "axios";
import Wifi from "../../../assets/icons/wifi.png";


import './styles.css'

const CardInternet = () => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiImg = process.env.REACT_APP_API_IMG;

    const [postagens, setPostagens] = useState([]);
    const [postagemFotos, setPostagemFotos] = useState({});

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchPostagens();
    }, []);

    const fetchPostagens = async () => {
        try {
            const response = await axios.get(`${apiUrl}/postagens`);
            setPostagens(response.data);

            const fotos = {};
            await Promise.all(response.data.map(async (postagem) => {
                const res = await axios.get(`${apiUrl}/fotos/${postagem._id}`);
                fotos[postagem._id] = res.data.map(foto => ({
                    ...foto,
                    url: `${apiImg}/assets/fotos/${foto.foto}`
                }));
            }));
            setPostagemFotos(fotos);
        } catch (error) {
            console.error("Erro ao buscar as postagens e fotos:", error);
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 postagem-body">
                    {postagens.map((postagem) => (
                        <div key={postagem._id}> {/* Define a key no elemento pai */}
                            <h2><img src={Wifi} alt="wifi" /> {postagem.title}</h2> {/* Exemplo de estrutura para título */}
                            <h3>{postagem.sub}</h3> {/* Exemplo para subtítulo */}
                            <TextRender htmlContent={postagem.text} /> {/* Renderiza o texto usando o componente TextRender */}
                        </div>
                    ))}
                </div>

                <div>
                    {postagens.map((postagem) => (
                        <>
                            <div className="postagem">
                                {postagemFotos[postagem._id] && postagemFotos[postagem._id].map((foto) => (
                                    <>
                                        <div className="foto-postagem">
                                            <img key={foto._id} src={foto.url} alt="Postagem" />
                                        </div>
                                    </>
                                ))}
                            </div>
                        </>
                    ))}
                    {postagens && postagens.length > 0 &&
                        <span> * Valor promocional válido somente para pagamentos até a data do vencimento. </span>
                    }
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    {postagens && postagens.length > 0 &&
                        <div className="buttons">
                            <a href="/internet" className="btn-hover color-7">Saiba mais...</a>
                            {/* <button >Contratar agora!</button> */}
                        </div>
                    }                   
                </div>
            </div>

        </div>

    );
}

export default CardInternet;