import React, { useState, useEffect } from 'react';
import { Button, Card, Form, Row, Col, Spinner } from 'react-bootstrap';
import axios from 'axios';
import botAvatar from '../../assets/images/gigabot.png';  // Avatar do bot
import './Chatbot.css';  // Importa o arquivo CSS

const Chatbot = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState(''); // Novo estado para armazenar o telefone
  const [step, setStep] = useState(0);
  const [options, setOptions] = useState([]);
  const [messages, setMessages] = useState([]);
  const [showChat, setShowChat] = useState(false); // Controla a visibilidade do chat
  const [planoEscolhido, setPlanoEscolhido] = useState(''); // Novo estado para armazenar o plano escolhido
  const [chatEnded, setChatEnded] = useState(false); // Novo estado para marcar o fim do chat
  const [botTyping, setBotTyping] = useState(false); // Estado para indicar se o bot está "digitando"

  const startChat = async () => {
    // Validação manual para verificar se o nome e o telefone estão preenchidos
    if (name.trim() === '' || phone.trim() === '') {
      alert('Por favor, preencha seu nome e telefone antes de iniciar o chat.');
      return;
    }

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/chatbot/start`, { name, phone });
    setMessages([...messages, { sender: 'bot', text: response.data.message }]);
    setOptions(response.data.options);
    setStep(1);
    setChatEnded(false); // Reiniciar o estado do chat quando reiniciar
  };

  const sendOption = async (option) => {
    try {
      setBotTyping(true); // Inicia o estado "digitando"
      
      // Delay simulado antes de exibir a resposta do bot (por exemplo, 1,5 segundos)
      setTimeout(async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/chatbot/next`, { name, option });
  
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: response.data.message },
        ]);
  
        // Verifica se a opção selecionada é um plano ou serviço e armazena o plano escolhido
        if (
          option.includes('Mbps') || 
          option.includes('Giga') || 
          option.includes('Plano') || 
          option.includes('Rural') || 
          option.includes('Bloqueio') ||  // Ajuste para Telefonia Fixa
          option.includes('Sem Bloqueio')
        ) {
          setPlanoEscolhido(option);  // Armazena o plano ou serviço escolhido
        }
  
        if (option === 'Atendimento via WhatsApp') {
          // Verifica se um plano foi realmente escolhido
          if (!planoEscolhido) {
            setMessages((prevMessages) => [
              ...prevMessages,
              { sender: 'bot', text: 'Nenhum plano foi selecionado.' },
            ]);
            setBotTyping(false);
            return;
          }
  
          // Cria a mensagem manualmente com base no plano escolhido e no nome da pessoa
          const messageParam = encodeURIComponent(`Olá, meu nome é ${name} e gostaria de falar sobre o seguinte plano: ${planoEscolhido}`);
  
          // Lógica do WhatsAppLink com userAgent
          const userAgent = navigator.userAgent.toLowerCase();
          const baseURL = "https://wa.me/556222000000";
          
          let whatsappLink;
          if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent)) {
            window.location.href = `whatsapp://send?phone=556222000000&text=${messageParam}`;
          } else {
            window.open(`${baseURL}?text=${messageParam}`, '_blank');
          }

                    
          // Abre o link do WhatsApp em uma nova aba ou no aplicativo
         window.open(whatsappLink, '_blank');
  
          setChatEnded(true);
          setBotTyping(false);
          return;
        }
  
        // Verifica se há próximas opções
        if (response.data.options.length > 0) {
          setOptions(response.data.options);
          setStep((prevStep) => prevStep + 1);
        } else {
          setOptions([]);
          setChatEnded(true);
        }
  
        setBotTyping(false);
      }, 1500);
    } catch (error) {
      console.error('Erro ao enviar a opção:', error);
      setBotTyping(false);
    }
  };
  

  const restartChat = () => {
    setName('');
    setPhone('');
    setMessages([]);
    setOptions([]);
    setStep(0);
    setChatEnded(false);
  };

  const closeChat = () => {
    setShowChat(false);
  };

  return (
    <>
      {/* Avatar suspenso no rodapé */}
      <div className="chatbot-avatar" onClick={() => setShowChat(!showChat)}>
        <img src={botAvatar} alt="Bot Avatar" />
      </div>

      {/* Chatbox que aparece/desaparece */}
      {showChat && (
        <div className="chatbot-container">
          <Card>
            <Card.Header className="chatbot-header">
              <h4>Chatbot</h4>
            </Card.Header>
            <Card.Body className="chatbot-body">
              {step === 0 ? (
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Digite seu nome:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Seu nome"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Digite seu telefone:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Seu telefone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Button variant="primary" style={{ backgroundColor: '#ff007f', border: 'none' }} onClick={startChat}>
                    Iniciar Chat
                  </Button>
                </Form>
              ) : (
                <>
                  {messages.map((msg, index) => (
                    <Row key={index} className={`chatbot-message ${msg.sender === 'bot' ? 'chatbot-bot-message' : 'chatbot-user-message'}`}>
                      {msg.sender === 'bot' && (
                        <Col xs={2}>
                          <img src={botAvatar} alt="Bot" style={{ width: '40px', borderRadius: '50%' }} />
                        </Col>
                      )}
                      <Col xs={msg.sender === 'bot' ? 10 : 12}>
                        <Card className={`chatbot-message-bubble ${msg.sender === 'bot' ? 'chatbot-bot-bubble' : 'chatbot-user-bubble'}`}>
                          <Card.Body>
                            <p>{msg.text}</p>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  ))}

                  {/* Indicador de "digitando" */}
                  {botTyping && (
                    <Row className="chatbot-typing-indicator">
                      <Col xs={2}>
                        <img src={botAvatar} alt="Bot" style={{ width: '40px', borderRadius: '50%' }} />
                      </Col>
                      <Col xs={10}>
                        <div className="typing-dots">
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {/* Opções finais: Reiniciar ou Fechar */}
                  {chatEnded ? (
                    <div className="chatbot-end-options">
                      <p>Obrigado por utilizar nosso atendimento! 😊</p>
                      <Button variant="primary" style={{ backgroundColor: '#ff007f', marginRight: '10px' }} onClick={restartChat}>
                        Reiniciar Chat
                      </Button>
                      <Button variant="secondary" onClick={closeChat}>
                        Fechar Chat
                      </Button>
                    </div>
                  ) : (
                    <>
                      {options.length > 0 && (
                        <div className="chatbot-options">
                          {options.map((option, index) => (
                            <Button
                              key={index}
                              variant="outline-primary"
                              style={{ backgroundColor: '#ff007f', color: '#fff', border: 'none' }}
                              onClick={() => sendOption(option)}
                            >
                              {option}
                            </Button>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default Chatbot;
