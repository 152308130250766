import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal, Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import CanaisList from '../../CanaisList/CanaisList';
import WifiTV from '../../../../assets/images/wifiTv.png';
import TV from '../../../../assets/icons/Icontv2.png';
import '../ComboTV.css';
import GigaLivre from '../../GigaLivre/GigaLivre';
import { FcStart } from "react-icons/fc";

const ComboWrapper = () => {
  const [combos, setCombos] = useState([]);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showChannels, setShowChannels] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    // Busca os combos da API quando o componente é montado
    const fetchCombos = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/card_combos`);
        setCombos(response.data);
      } catch (error) {
        console.error("Erro ao buscar combos:", error);
      }
    };
    fetchCombos();
  }, []);

  const handleShowMoreInfo = (combo) => {
    setSelectedPlan(combo);
    setShowMoreInfo(true);
  };

  const handleShowChannels = (combo) => {
    setSelectedPlan(combo);
    setShowChannels(true);
    console.log(combo);
  };

  const handleClose = () => {
    setShowMoreInfo(false);
    setShowChannels(false);
  };

  return (
    <>
      <Container className="app-giga-play my-5">
        <h1 className="text-center-title mb-4">Combo, Internet + TV</h1>
        <p>
          Assista seus programas favoritos a qualquer hora e em qualquer lugar com o App Giga Play. Disponível para Android, iOS, TVs Samsung e LG.
        </p>
        <Row className="my-4">
          {combos.map((combo, index) => (
            <Col key={index} md={3} sm={6}>
              <Card className="combo-card">
                <Card.Img variant="top" src={WifiTV} alt={combo.title} className="combo-image" />
                <Card.Header>{combo.title}</Card.Header>
                <Card.Body>
                  <Card.Title style={{ fontSize: '1.05rem', color: '#333', fontWeight: '500', lineHeight: '1.5', marginTop: '20px' }}>
                    {combo.subtitle}
                  </Card.Title>
                  <Card.Text style={{ fontSize: '0.9rem', color: '#333', fontWeight: '400', lineHeight: '1.5', marginTop: '20px' }}>
                  <FcStart /> {combo.text}
                  </Card.Text>
                  <Button className="mais-button" onClick={() => handleShowMoreInfo(combo)}>
                    Mais informações
                  </Button> {' '}
                  <Button className="canais-button" onClick={() => handleShowChannels(combo)}>
                    Ver canais
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Modal para Mais Informações */}
      <Modal show={showMoreInfo} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className='icon-tv'>
              <img src={TV} alt='icon-tv' /> {selectedPlan?.subtitle}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPlan?.planos.length > 0 && (
            <Tabs defaultActiveKey={selectedPlan.planos[0].planname} id="plans-tabs">
              {selectedPlan.planos.map((plan, index) => (
                <Tab eventKey={plan.planname} title={plan.planname} key={index}>
                  <Row className="plan-details">
                    <Col md={6}>
                      <h4 className="plan-canais">{plan.numberofchannels}</h4>
                      <h4 className="plan-tv">{plan.tvplan}</h4>
                      <h4 className="plan-tvprice">R$ {(parseFloat(plan.tvprice)).toFixed(2)}</h4>
                    </Col>
                    <Col md={6}>
                      <h4 className='plan-internet'>INTERNET DE {plan.planname}</h4>
                      <div className='plan-price-discounted'>
                        <h4 className="plan-internetprice">de R$ {(parseFloat(plan.internetprice)).toFixed(2)}</h4>
                        <h4 className='plan-discountedprice'>por R$ {(parseFloat(plan.internetprice - plan.discountprice)).toFixed(2)}</h4>
                        {/* <span>De: R${(parseFloat(selectedProduct?.price)).toFixed(2)}</span>
                <p>Por: R${(parseFloat(selectedProduct?.price) * 0.9).toFixed(2)}</p> */} <br />
                      </div>
                      <br />
                      <h3 className='plan-total'>
                        TOTAL R$ {(
                          parseFloat(plan.internetprice) -
                          parseFloat(plan.discountprice) +
                          parseFloat(plan.tvprice)
                        ).toFixed(2)}*/mês
                      </h3>
                    </Col>
                  </Row>
                </Tab>
              ))}
            </Tabs>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Fechar</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para Ver Canais */}
      <Modal show={showChannels} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Lista de Canais</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CanaisList plan={selectedPlan?.subtitle} />
          <GigaLivre />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ComboWrapper;
