import React from "react";
import Slider from "react-slick";

import Animal from "../../assets/images/animal.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./InfiniteSlider.css"; // Import the custom styles

// Importing images
import logo1 from "../../assets/icons/history.png";
import logo2 from "../../assets/icons/h&h.png";
import logo3 from "../../assets/icons/discovery.png";
import logo4 from "../../assets/icons/disney.png";
// import logo5 from "../../assets/icons/anhanguera.png";
import logo6 from "../../assets/icons/fishtv.png";
// import logo7 from "../../assets/icons/serradourada.png";
import logo8 from "../../assets/icons/turbo.png";
import logo9 from "../../assets/icons/animalplanet.png";
import logo10 from "../../assets/icons/jpnews.png";

// Array of logos with imported images
const logos = [
  { id: 1, src: logo1, alt: "Logo 1" },
  { id: 2, src: logo2, alt: "Logo 2" },
  { id: 3, src: logo3, alt: "Logo 3" },
  { id: 4, src: logo4, alt: "Logo 4" },
  // { id: 5, src: logo5, alt: "Logo 5" },
  { id: 6, src: logo6, alt: "Logo 6" },
  // { id: 7, src: logo7, alt: "Logo 7" },
  { id: 8, src: logo8, alt: "Logo 8" },
  { id: 9, src: logo9, alt: "Logo 9" },
  { id: 10, src: logo10, alt: "Logo 10" },
];

const InfiniteSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <>
      <div className="container">
        <div className="slider-container">
          <Slider {...settings}>
            {logos.map((logo) => (
              <div key={logo.id} className="slide">
                <img src={logo.src} alt={logo.alt} className="logo-image" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="animal">
        <img src={Animal} alt="Animal" />
      </div>
    </>
  );
};

export default InfiniteSlider;
