import React, { useState } from "react";
import './GigaFull.css';

// Importar as imagens
import Img1 from '../../../assets/canais/full/1.png';
import Img2 from '../../../assets/canais/full/2.png';
import Img3 from '../../../assets/canais/full/3.png';
import Img4 from '../../../assets/canais/full/4.png';
import Img5 from '../../../assets/canais/full/5.png';
import Img6 from '../../../assets/canais/full/6.png';
import Img7 from '../../../assets/canais/full/7.png';
import Img8 from '../../../assets/canais/full/8.png';
import Img9 from '../../../assets/canais/full/9.png';
import Img10 from '../../../assets/canais/full/10.png';
// import Img11 from '../../../assets/canais/full/11.png';
import Img12 from '../../../assets/canais/full/12.png';
import Img13 from '../../../assets/canais/full/13.png';
import Img14 from '../../../assets/canais/full/14.png';
import Img15 from '../../../assets/canais/full/15.png';
import Img16 from '../../../assets/canais/full/16.png';
import Img17 from '../../../assets/canais/full/17.png';
import Img18 from '../../../assets/canais/full/18.png';
import Img19 from '../../../assets/canais/full/19.png';
import Img20 from '../../../assets/canais/full/20.png';
import Img21 from '../../../assets/canais/full/21.png';
import Img22 from '../../../assets/canais/full/22.png';
import Img23 from '../../../assets/canais/full/23.png';
import Img24 from '../../../assets/canais/full/24.png';
import Img25 from '../../../assets/canais/full/25.png';
import Img26 from '../../../assets/canais/full/26.png';
import Img27 from '../../../assets/canais/full/27.png';
import Img28 from '../../../assets/canais/full/28.png';
import Img29 from '../../../assets/canais/full/29.png';
import Img30 from '../../../assets/canais/full/30.png';
import Img31 from '../../../assets/canais/full/31.png';
import Img32 from '../../../assets/canais/full/32.png';
import Img33 from '../../../assets/canais/full/33.png';
import Img34 from '../../../assets/canais/full/34.png';
import Img35 from '../../../assets/canais/full/35.png';
import Img36 from '../../../assets/canais/full/36.png';
import Img37 from '../../../assets/canais/full/37.png';
import Img38 from '../../../assets/canais/full/38.png';
import Img39 from '../../../assets/canais/full/39.png';
import Img40 from '../../../assets/canais/full/40.png';
import Img41 from '../../../assets/canais/full/41.png';
import Img42 from '../../../assets/canais/full/42.png';
import Img43 from '../../../assets/canais/full/43.png';
import Img44 from '../../../assets/canais/full/44.png';
import Img45 from '../../../assets/canais/full/45.png';
import Img46 from '../../../assets/canais/full/46.png';
import Img47 from '../../../assets/canais/full/47.png';
import Img48 from '../../../assets/canais/full/48.png';
import Img49 from '../../../assets/canais/full/49.png';
import Img50 from '../../../assets/canais/full/50.png';
import Img51 from '../../../assets/canais/full/51.png';
import Img52 from '../../../assets/canais/full/52.png';
import Img53 from '../../../assets/canais/full/53.png';
import Img54 from '../../../assets/canais/full/54.png';
import Img55 from '../../../assets/canais/full/55.png';
import Img56 from '../../../assets/canais/full/56.png';
import Img57 from '../../../assets/canais/full/57.png';
import Img58 from '../../../assets/canais/full/58.png';
import Img59 from '../../../assets/canais/full/59.png';
import Img60 from '../../../assets/canais/full/60.png';
import Img61 from '../../../assets/canais/full/61.png';
import Img62 from '../../../assets/canais/full/62.png';
import Img63 from '../../../assets/canais/full/63.png';
import Img64 from '../../../assets/canais/full/64.png';
import Img65 from '../../../assets/canais/full/65.png';
import Img66 from '../../../assets/canais/full/66.png';
import Img67 from '../../../assets/canais/full/67.png';



// Criar o array de imagens
const canais = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img12, Img13, Img14, Img15, Img16, Img17, Img18, Img19, Img20, Img21, Img22, Img23, Img24, Img25, Img26, Img27, Img28, Img29, Img30,	Img31,	Img32,	Img33,	Img34,	Img35,	Img36,	Img37,	Img38, Img39,	Img40,	Img41,	Img42,	Img43,	Img44,	Img45, Img46,	Img47,	Img48, Img49, Img50, Img51,	Img52,	Img53,	Img54,	Img55,	Img56,	Img57,	Img58,	Img59,	Img60,	Img61,	Img62,	Img63,	Img64,	Img65,	Img66,	Img67];


const GigaFull = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="canais-list">
      <div className="canais-list-title"><h1>Canais Giga Full</h1></div>
        {canais.map((canal, index) => (
          <div key={index} className="canal-item">
            <img src={canal} alt={`Canal ${index + 1}`} className="canal-image" />
          </div>
        ))}
      </div>

    </>
  );
}

export default GigaFull;
