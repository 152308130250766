import React from 'react';
import ContactFormCategoria from '../../components/ContactFormCategoria/ContactFormCategoria';

import './ContactPage.css';
import WhatsApp from '../../components/WhatsApp/WhatsApp';

const ContactPage = () => {
  return (
    <>
      <ContactFormCategoria />
    </>
  );
};

export default ContactPage;
