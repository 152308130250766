import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import './GigaLivre.css';

// Importar as imagens
import Img1 from '../../../assets/canais/cortesia/1.png';
import Img2 from '../../../assets/canais/cortesia/2.png';
import Img3 from '../../../assets/canais/cortesia/3.png';
import Img4 from '../../../assets/canais/cortesia/4.png';
import Img5 from '../../../assets/canais/cortesia/5.png';
import Img6 from '../../../assets/canais/cortesia/6.png';
import Img7 from '../../../assets/canais/cortesia/7.png';
import Img8 from '../../../assets/canais/cortesia/8.png';
import Img9 from '../../../assets/canais/cortesia/9.png';
import Img10 from '../../../assets/canais/cortesia/10.png';
import Img11 from '../../../assets/canais/cortesia/11.png';
import Img12 from '../../../assets/canais/cortesia/12.png';
import Img13 from '../../../assets/canais/cortesia/13.png';
import Img14 from '../../../assets/canais/cortesia/14.png';
import Img15 from '../../../assets/canais/cortesia/15.png';
import Img16 from '../../../assets/canais/cortesia/16.png';
import Img17 from '../../../assets/canais/cortesia/17.png';
// import Img18 from '../../../assets/canais/cortesia/18.png';
// import Img19 from '../../../assets/canais/cortesia/19.png';
import Img20 from '../../../assets/canais/cortesia/20.png';
import Img21 from '../../../assets/canais/cortesia/21.png';
import Img22 from '../../../assets/canais/cortesia/22.png';
import Img23 from '../../../assets/canais/cortesia/23.png';
import Img24 from '../../../assets/canais/cortesia/24.png';
import Img25 from '../../../assets/canais/cortesia/25.png';
import Img26 from '../../../assets/canais/cortesia/26.png';
// import Img29 from '../../../assets/canais/cortesia/27.png';
import Img27 from '../../../assets/canais/cortesia/28.png';
import Img28 from '../../../assets/canais/cortesia/29.png';
import Img30 from '../../../assets/canais/cortesia/30.png';
import Img31 from '../../../assets/canais/cortesia/31.png';
import Img32 from '../../../assets/canais/cortesia/32.png';
import Img33 from '../../../assets/canais/cortesia/33.png';
import Img34 from '../../../assets/canais/cortesia/34.png';
import Img35 from '../../../assets/canais/cortesia/35.png';
import Img36 from '../../../assets/canais/cortesia/36.png';
import Img37 from '../../../assets/canais/cortesia/37.png';
import Img39 from '../../../assets/canais/cortesia/39.png';
import Img40 from '../../../assets/canais/cortesia/Cultura.png';
import Img41 from '../../../assets/canais/cortesia/jp_news.png';
import Img42 from '../../../assets/canais/cortesia/Gazeta.png';
import Img43 from '../../../assets/canais/cortesia/Loading.png';
import Img44 from '../../../assets/canais/cortesia/milagro.png';
import Img45 from '../../../assets/canais/cortesia/RedeFamilia.png';
import Img46 from '../../../assets/canais/cortesia/Rit.png';
import Img47 from '../../../assets/canais/cortesia/RSC.png';
import Img48 from '../../../assets/canais/cortesia/TerraViva.png';
import Img49 from '../../../assets/canais/cortesia/TVT.png';


// Criar o array de imagens
const canais = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img11, Img12, Img13, Img14, Img15, Img16, Img17, Img20, Img21, Img22, Img23, Img24, Img25, Img26, Img27, Img28, Img30, Img31, Img32, Img33, Img34, Img35, Img36, Img37, Img39, Img40, Img41, Img42, Img43, Img44, Img45, Img46, Img47, Img48, Img49 ];
// const canais = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img11, Img12, Img13, Img14, Img15, Img16, Img17, Img18, Img19, Img20, Img21, Img22, Img23, Img24, Img25, Img26, Img27, Img28, Img29, Img30, Img31, Img32, Img33, Img34, Img35, Img36, Img37, Img39, Img40, Img41, Img42, Img43, Img44, Img45, Img46, Img47, Img48, Img49 ];

const GigaLivre = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="canais-list">
        <div className="canais-list-title"><h1>Canais Cortesia</h1></div>
        {canais.map((canal, index) => (
          <div key={index} className="canal-item">
            <img src={canal} alt={`Canal ${index + 1}`} className="canal-image" />
          </div>
        ))}
      </div>
        <div className="rodape-cortesia">*Os canais cortesia podem sofrer alterações para
        mais ou menos, sem aviso prévio da prestadora</div>
    </>
  );
}

export default GigaLivre;
