import React, { useState, useEffect } from "react";
import axios from "axios";

import './CardImage.css';

const CardImage = () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiImg = process.env.REACT_APP_API_IMG;

  const [randomPhone, setRandomPhone] = useState(null);

  useEffect(() => {
    fetchRandomPhone();
  }, []);

  const fetchRandomPhone = async () => {
    try {
      const response = await axios.get(`${apiUrl}/random`);
      setRandomPhone(response.data);
    } catch (error) {
      console.error("Erro ao buscar o rural aleatório:", error);
    }
  };
  return (
    <>
      <div className="container-fluid phone">
        <div className="row">
          {randomPhone && (
            <>

              <div
                className="phone-container "
                style={{
                  backgroundImage: `url(${apiImg}/assets/phones/${randomPhone.foto})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  with: '100%',
                  // height: '400px',
                }}
              >

                <div className="row">
                  <div className="col-sm-12 col-md-9">
                    <div className="title-phone">
                      {/* <h1><img src={Farm} alt="call center" /> {randomPhone.title}</h1> */}
                    </div>
                    <div className="sub">
                      {/* <h3>{randomPhone.sub}</h3>
                            <div className="text" dangerouslySetInnerHTML={{ __html: randomPhone.text }} /> */}
                      <div className="buttons-phone">
                        {/* <button className="btn-hover color-9">Escolha seu plano!</button> */}
                        <div className="buttons">
                          <a href="/telefone" className="btn-hover color-9">Escolha seu plano!</a>
                          {/* <button >Contratar agora!</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                  </div>
                </div>

              </div>
            </>
          )}
          {/* {randomPhone && Object.keys(randomPhone).length > 0 &&
        <span> * Valor promocional válido somente para pagamentos até a data do vencimento. </span>
    } */}
        </div>
      </div>
    </>
  )
}
export default CardImage;