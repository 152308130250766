import React from 'react';
import GigabytePlay from '../GigabytePlay/GigabytePlay';

import './AppGigaPlay.css'; // Importando o arquivo de estilos
import ComboWrapper from '../ComboTV/ComboWrapper/ComboWrapper';
const AppGigaPlay = () => {
  return (
    <>
      <GigabytePlay />
      <ComboWrapper />
    </>
  );
};

export default AppGigaPlay;
