import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Tabs, Tab } from 'react-bootstrap';
import CanaisList from '../CanaisList/CanaisList';
import WifiTV from '../../../assets/images/wifiTv.png';
import TV from '../../../assets/icons/Icontv2.png';
import './ComboTV.css'; // Importando o arquivo de estilos
import ComboWrapper from './ComboWrapper/ComboWrapper';
import ComboHearder from './ComboHeader/ComboHeader';

const ComboTV = () => {

  return (
    <>
      <ComboHearder />
      <ComboWrapper />
    </>
  );
};

export default ComboTV;
