import { useEffect } from 'react';

const AppRedirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // Redireciona para Google Play
      window.location.href = 'https://play.google.com/store/apps/details?id=br.com.badbit.gigabytetelecom&hl=pt_BR';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redireciona para App Store
      window.location.href = 'https://apps.apple.com/br/app/gigabyte-telecom-cliente/id1620548702';
    } else {
      // Dispositivo não suportado, redireciona para a página inicial
      window.location.href = '/';
    }
  }, []);

  return null; // Este componente não precisa renderizar nada
};

export default AppRedirect;



