import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import './GigaStart.css';

// Importar as imagens
import Img1 from '../../../assets/canais/start/001-LogoA&E_Network_logo.svg.png';
import Img2 from '../../../assets/canais/start/002-arte1.png';
import Img3 from '../../../assets/canais/start/003-LogoBandNews_TV_logo_2010.svg.png';
import Img4 from '../../../assets/canais/start/004-LogoBandSports_logo.svg.png';
import Img5 from '../../../assets/canais/start/005-Logocnnbrasil.png';
import Img6 from '../../../assets/canais/start/006Logo_CNT.png';
import Img7 from '../../../assets/canais/start/007-Cartoon_Network.png';
import Img8 from '../../../assets/canais/start/008-LogoChefTV.png';
import Img9 from '../../../assets/canais/start/009-LogoCinebrasilTV.png';
import Img10 from '../../../assets/canais/start/0010-LogoDiscoveryChannelHD.png';
import Img11 from '../../../assets/canais/start/0011-Logodiscoveryhome-and-healthlogo.png';
import Img12 from '../../../assets/canais/start/0012-Logodiscovery-kids-logo.png';
import Img13 from '../../../assets/canais/start/0013-LogoDisney_Junior.png';
import Img14 from '../../../assets/canais/start/0014-LogoE.png';
import Img15 from '../../../assets/canais/start/0015-LogoESPN_Extra_Brazil_logo.png';
import Img16 from '../../../assets/canais/start/0016-LogoESPNLogo.png';
import Img17 from '../../../assets/canais/start/0017-Logofishtv-300x86.png';
import Img18 from '../../../assets/canais/start/0018-MtvHD_.png';
import Img19 from '../../../assets/canais/start/0019-LogoPrimeBoxBrazil2019.png';
import Img20 from '../../../assets/canais/start/0020-LogoRecordNewsHD.png';
import Img21 from '../../../assets/canais/start/0021-LogoSony_Channel_Logo.png';
import Img22 from '../../../assets/canais/start/0022-LogoTNT.png';
import Img23 from '../../../assets/canais/start/0023-LogoWarner2018LA.png';
import Img24 from '../../../assets/canais/start/0040_zoomoo_kids.png';
import Img25 from '../../../assets/canais/start/40-nick_jr.png';
import Img26 from '../../../assets/canais/start/0041-tbc.png';
import Img29 from '../../../assets/canais/start/0042-cartoonito.png';
import Img27 from '../../../assets/canais/start/52-tv_ratimbum.png';
import Img28 from '../../../assets/canais/start/re_0016-LogoESPNLogo.png';


// Criar o array de imagens
const canais = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img11, Img12, Img13, Img14, Img15, Img16, Img17, Img18, Img19, Img20, Img21, Img22, Img23, Img24, Img25, Img26, Img27, Img28, Img29 ];

const GigaStart = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="canais-list">
      <div className="canais-list-title"><h1>Canais Giga Start</h1></div>
        {canais.map((canal, index) => (
          <div key={index} className="canal-item">
            <img src={canal} alt={`Canal ${index + 1}`} className="canal-image" />
          </div>
        ))}
      </div>
    </>
  );
}

export default GigaStart;
