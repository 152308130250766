// ProductList.jsx
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Motion, spring } from 'react-motion';
import axios from "axios";
import './ProductList.css';
import SeparatorBar from "../../../components/SeparatorBar/SeparatorBar";
import Whats from "../../../assets/images/whatsapp2.fw.png";
import Call from "../../../assets/images/call3.png";

const ProductList = ({ categoryId, categoryName }) => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiImg = process.env.REACT_APP_API_IMG;

  useEffect(() => {
    if (categoryId) {
      fetchProducts();
    }
  }, [categoryId]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products?category=${categoryId}`
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Erro ao buscar os produtos:", error);
    }
  };


  const TextRender = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  const handleShowModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  const message = "Olá! gostaria de saber os preços dos roteadores";

  const [whatsAppLink, setWhatsAppLink] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const baseURL = "https://wa.me/556222000000";
    const messageParam = encodeURIComponent(`Olá, entre para falar sobre: ${message}`);

    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent)) {
      setWhatsAppLink(`whatsapp://send?phone=556222000000&text=${messageParam}`);
    } else {
      setWhatsAppLink(`${baseURL}?text=${messageParam}`);
    }
  }, [message]);


  return (
    <>
      <div className="d-flex flex-wrap justify-content-between">
        {products.length > 0 ? (
          products.map((product) => (
            <Motion
              key={product._id}
              defaultStyle={{ opacity: 0, translateX: 80, scale: 1 }}
              style={{ opacity: spring(1), translateX: spring(0), scale: spring(1) }}
            >
              {interpolatedStyle => (
                <div
                  className="animated-card col-sm-12 col-md-3"
                  style={{
                    opacity: interpolatedStyle.opacity,
                    transform: `translateX(${interpolatedStyle.translateX}px) scale(${interpolatedStyle.scale})`
                  }}
                >
                  <div className="card-home">
                    <div className="card-title">
                      <h3>{product.name}</h3>
                      <SeparatorBar />
                    </div>
                    <div className="card-img">
                      <img src={`${apiImg}/assets/products/${product.foto}`} alt="Carousel" />
                    </div>
                    {/* <span>{product.description}</span> */}
                    <div className="product-card"><TextRender htmlContent={product.description} /></div>                    
                    <Button className="custom-button-card" onClick={() => handleShowModal(product)}>
                      Saiba mais...
                    </Button>
                  </div>
                </div>
              )}
            </Motion>
          ))
        ) : (
          <p>Nenhum produto cadastrado para essa categoria</p>
        )}
      </div>
      <hr />
      <br /><br />
      {/* Modal para mostrar detalhes do card */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedProduct?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content-container">            
                <span>Oferta especial para cliente Giga, entre em contato para consultar os preços.</span>
            <div className="modal-img">
              <div className="modal-price">
                {/* <span>De: R${(parseFloat(selectedProduct?.price)).toFixed(2)}</span>
                <p>Por: R${(parseFloat(selectedProduct?.price) * 0.9).toFixed(2)}</p> */}
              </div>
              <img src={`${apiImg}/assets/products/${selectedProduct?.foto}`} alt="Detalhes do Card" />
            </div>
            <div className="contato-modal">              
              <img src={Call} alt="" />Ligue 0800 062 2200
              <a href={whatsAppLink} target="_blank"><img src={Whats} alt="" />62 2200-0000</a>
            </div>
            <SeparatorBar />
            <div className="product-card"><TextRender htmlContent={selectedProduct?.description || ''} /></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default ProductList;
