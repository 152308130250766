import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import android from '../../assets/images/AppStore.png';
import ios from '../../assets/images/AppStore.png';
import samsung from '../../assets/images/AppStore.png';
import lg from '../../assets/images/AppStore.png';

import './AppGigaClient.css'; // Importando o arquivo de estilos
import GigabyteClient from '../GigabyteClient/GigabyteClient';

const AppGigaClient = () => {
  return (
    <>
      
      <Container className="app-giga-play my-5">
        <div  className="text-center mb-4">
        <h1>App Gigabyte Cliente</h1>
        <h2>Comodidade e tranquilidade</h2>
        </div>
        {/* <Row className="my-4">
          <Col md={3} sm={6}>
            <Card className="app-card text-center">
              <Card.Img variant="top" src={android} alt="Android" className="app-image" />
              <Card.Body>
                <Card.Title>Android</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6}>
            <Card className="app-card text-center">
              <Card.Img variant="top" src={ios} alt="iOS" className="app-image" />
              <Card.Body>
                <Card.Title>iOS</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6}>
            <Card className="app-card text-center">
              <Card.Img variant="top" src={samsung} alt="Samsung" className="app-image" />
              <Card.Body>
                <Card.Title>TV Samsung</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6}>
            <Card className="app-card text-center">
              <Card.Img variant="top" src={lg} alt="LG" className="app-image" />
              <Card.Body>
                <Card.Title>TV LG</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
      </Container>
      <GigabyteClient />
    </>
  );
};

export default AppGigaClient;
