
import SeparatorImg from "../../assets/images/separator.png";

import './SeparatorBar.css';

const SeparatorBar = () => {
  return (
    <>
      <div className="separator-phone">
        <img src={SeparatorImg} alt="separador" />
      </div>
    </>
  )
}
export default SeparatorBar;