import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import PhoneIcon from "../../../assets/icons/phone.png";
import PostItWhite from "../../../assets/icons/branco.png";
import PostItYellow from "../../../assets/icons/amarelo.png";
import PostItGreen from "../../../assets/icons/verde.png";
import PostItBlack from "../../../assets/icons/preto.png";
import PostItBlue from "../../../assets/icons/azul.png";
import PostItPink from "../../../assets/icons/rosa.png";
import "./Plans.css";

const Plans = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/plansPhone`);
        const data = await response.json();
        setPlans(data);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar os planos:", error);
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  // Define a imagem do alfinete com base na categoria ou tipo
  const getPostItImage = (index) => {
    const images = [
      PostItYellow,
      PostItGreen,
      PostItBlack,
      PostItWhite,
      PostItBlue,
      PostItPink,
    ];
    return images[index % images.length]; // Cicla através das imagens
  };

  if (loading) {
    return <p>Carregando planos...</p>;
  }

  const renderPlans = (category) => {
    return plans
      .filter((plan) => plan.category === category)
      .map((plan, index) => (
        <Col key={plan.id} md={4} xl={3}>
          <Card className={`${plan.color}-bg order-card`}>
            <Card.Body>
              <div className="icon-card">
                <div className="post-it">
                  <img src={getPostItImage(index)} alt="alfinete" />
                </div>
              </div>
              <h6 className="m-b-2">{plan.description}</h6>
              <h2 className="text-right">
                <span>{plan.price}</span>
              </h2>
              <div className="m-b-0 observacao">
                {plan.notes}
                <span className="f-right"></span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ));
  };

  return (
    <Container className="my-5">
      <h1 className="text-center mb-4">Planos ideais pra você</h1>
      <p className="text-center">
        Faça parte da família <strong>#GIGA</strong>, escolha o plano perfeito
        para você.
      </p>

      <h2 className="mt-5">
        <img src={PhoneIcon} alt="fone" /> Urbano
      </h2>
      <Row>{renderPlans("Urbano")}</Row>

      <h2 className="mt-5">
        <img src={PhoneIcon} alt="fone" /> Rural
      </h2>
      <Row>{renderPlans("Rural")}</Row>
    </Container>
  );
};

export default Plans;
