import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';

import './VideoGallery.css';

const VideoGallery = () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiImg = process.env.REACT_APP_API_IMG;

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${apiUrl}/videos`);
        setVideos(response.data); // Supondo que a API retorne um array de objetos de vídeo com uma propriedade 'midia'
      } catch (error) {
        console.error('Erro ao buscar vídeos:', error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <Container>
      <Row>
        <div className='video-dicas'>
          <h1>Dicas e Soluções para um Funcionamento Ideal da Internet com a Gigabyte Telecom</h1>
        </div>

        {videos.map((video, index) => (
          <Col md={4} sm={12} key={index} className="mb-4">
            <div className="video-wrapper">
              <video width="100%" controls>
                <source src={`${apiImg}/assets/videos/${video.midia}`} type="video/mp4" />
                Seu navegador não suporta o elemento de vídeo.
              </video>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default VideoGallery;
