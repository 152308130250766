
import Phone from "../../../assets/icons/call3.png";
import SeparatorImg from "../../../assets/images/separator.png";

import './Separator.css';


const Separator = () => {
  return (
    <>
      <div className="separator-phone">
        <img src={SeparatorImg} alt="separador" />
      </div>
      <div className="container">
        <div className="row">
          <div className="phone-title"><br />
            <h2><img src={Phone} alt="" /> Plano de telefonia fixa ideal pra você.</h2>
            <p>Faça parte da família <strong>#GIGA</strong>, escolha o plano perfeito para você.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default Separator;