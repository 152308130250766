import React from "react";

import './styles.css';

const Privacidade = () => {
  return (
    <>
      <div className="container">
        {/* <h2>Política de Privacidade</h2> */}
        <ol>
          <li className="cookies">OBJETIVO</li>
          <p>As Leis sobre dados pessoais estão mudando no Brasil e nós da<strong> GIGABYTE TELECOM </strong>
            precisamos alertá-los sobre a utilização de seus dados.</p>
          <p>A <strong>GIGABYTE TELECOM</strong>, valoriza a transparência, segurança e a privacidade, conduzindo
            seus dados pessoais e a segurança da informação dentro do seu escopo de atuação. Quando você
            usa os nossos serviços, está confiando a nós suas informações. Entendemos que isso é uma
            grande responsabilidade</p>
          <p>A <strong>POLÍTICA DE PRIVACIDADE</strong> é o documento que apresenta aos nossos assinantes
            informações sobre a forma de tratamento dos dados pessoais, quais dados são tratados, direito
            dos titulares, os motivos e necessidades desse tratamento e a forma como os assinantes podem
            gerenciar e excluir informações.</p>
          <p>A presente <strong>POLÍTICA DE PRIVACIDADE</strong> se aplica a todos os nossos assinantes para a
            utilização do portal assinante através do site www.gigabytetelecom.com.br e integra aos
            contratos de prestação de serviços, feito pela prestadora <strong>MELO TELECOMUNICAÇÕES
              LTDA-ME</strong>, devidamente inscrita no <strong>CNPJ: 08.664.106/0001-00</strong>, com sede na <strong>Avenida
                Bernardo Sayão, 171, Bairro Residencial Primavera, da cidade de Jaraguá/GO.</strong></p>
          <p>Todas as condições do documento estão em conformidade com a <strong>LEI GERAL DE
            PROTEÇÃO DE DADOS PESSOAIS (Lei 13.709/18)</strong>, o <strong>MARCO CIVIL DA INTERNET
              (Lei 12.965/14)</strong> e o <strong>CÓDIGO DE DEFESA DO CONSUMIDOR (Lei 8078/90).</strong></p>
          <p>Este espaço está disponível para tirar suas dúvidas, sobre nossa política de proteção de dados e
            segurança dos seus dados pessoais.</p>
          <p>A <strong>GIGABYTE TELECOM</strong> poderá atualizar está <strong>POLÍTICA DE PRIVACIDADE</strong> em
            decorrência de eventual atualização normativa, razão pela qual se convida o assinante a
            consultar periodicamente esta seção.
          </p>

          <li className="cookies">ABRANGÊNCIA</li>
          <p>Esta política é aplicável à <strong>GIGABYTE TELECOM.</strong></p>
          <li className="cookies">REFERÊNCIA</li>
          <ul className="cookies-ol">
            <li>Lei Geral de Proteção de Dados (Lei 13.709/18)</li>
            <li>Marco Civil da Internet (Lei 12.965/14)</li>
            <li>Código de Defesa do Consumidor (Lei 8078/90)</li>
          </ul>

          <li className="cookies">DEFINIÇÕES</li>
          <ol className="cookies-ol">
            <li><span>DADOS PESSOAIS</span></li>
            <p>Dados relacionados à pessoa natural, identificada ou identificável.</p>

            <li><span>CONTROLADOR DE DADOS</span></li>
            <p>Pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões
              referentes ao tratamento de <strong>DADOS PESSOAIS</strong>.</p>
            <li><span>OPERADOR DE DADOS</span></li>
            <p>Pessoa natural ou jurídica, de poder público ou privado, que realiza o tratamento de <strong>DADOS
              PESSOAIS</strong> em nome do <strong>CONTROLADOR</strong>.</p>

            <li><span>TITULAR DOS DADOS</span></li>
            <p>Pessoa natural a quem se referem os dados pessoais que são objetos de tratamento.</p>

            <li><span>BANCO DE DADOS</span></li>
            <p>Conjunto estruturado de dados estabelecidos em um ou vários locais, em suporte eletrônico ou
              físico.</p>

            <li><span>TRATAMENTO DE DADOS</span></li>
            <p>Toda operação em <strong>DADOS PESSOAIS</strong>, com a que se referem ao tratamento, produção,
              recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição,
              processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da
              informação, modificação, comunicação, transferência ou extração.</p>
          </ol>

          <li className="cookies">COMO A GIGABYTE TELECOM TRATA SEUS DADOS:</li>
          <ol className="cookies-ol">
            <li>TRATAMENTO</li>
          </ol>
          <p>A <strong>GIGABYTE TELECOM</strong> é controladora de <strong>DADOS PESSOAIS</strong> e nessa qualidade, coleta e
            promove o tratamento de dados de seus clientes/usuários, respeitando os princípios de proteção
            de dados e isso inclui a minimização, utilizando apenas as informações necessárias para
            execução do contrato, onde as partes tem por objetivo à prestação do serviço de <strong>INTERNET,
              TELEFONIA FIXA</strong> e <strong>TV</strong> por <strong>ASSINATURA</strong>.
          </p>
          <li className="cookies">QUAIS OS DADOS A GIGABYTE TELECOM COLETA?</li>
          <p>Dados cadastrais:</p>
          <ul className="cookies-ol">
            <li>Nome ou Razão Social</li>
            <li>CPF ou CNPJ</li>
            <li>RG</li>
            <li>Data de Nascimento</li>
            <li>Endereço de Instalação</li>
            <li>Telefone</li>
            <li>Foto de Documento de Identificação</li>
            <li>E-mail</li>
          </ul>
          <p>A partir deles, podemos identificar o assinante, além de garantir uma maior segurança e bemestar as suas necessidades.</p>
          <p>A <strong>GIGABYTE TELECOM NÃO</strong> trata <strong>DADOS SENSÍVEIS</strong>, como origem racial ou étnica,
            convicção religiosa, opinião política, filiação a sindicato ou à organização religiosa, filosófica
            ou política, à saúde à sua vida sexual.</p>
          <p>A <strong>GIGABYTE TELECOM NÃO</strong> realiza o tratamento de dados de titulares de menor ou
            adolescentes.
          </p>

          <li className="cookies">POR QUANTO TEMPO A GIGABYTE TELECOM TRATA SEUS DADOS?</li>
          <p>A <strong>GIGABYTE TELECOM</strong> trata seus dados pelo tempo que durar a prestação do serviço ou o
            cumprimento das finalidades previstas no presente documento, conforme no inciso <strong>I do artigo
              15 da Lei 13.709/18</strong>, mas também precisando manter seus dados após o termino da sua relação
            para o cumprimento da Lei, como nos casos em que seja necessário fornecer dados às
            autoridades públicas ou mesmo realização de defesa em processos judiciais, ou proteção ao crédito em que os dados cadastrais e de faturamento serão armazenados pelo tempo mínimo de
            <strong> 05 (cinco) anos</strong>.</p>

          <li className="cookies">DIREITO DO TITULAR DE DADOS</li>
          <p>O cliente/usuário poderá solicitar ao nosso <strong>ENCARREGADO DE DADOS PESSOAIS
            (DPO)</strong> a confirmação da existência tratamento de dados pessoais, retificação, anonimato,
            portabilidade, compartilhamento, consentimento, revogação do consentimento ou exclusão,
            desde que decorrido o prazo legal mínimo relacionado à guarda de dados.</p>

          <li className="cookies">COMPARTILHAMENTO DOS DADOS</li>
          <p>Para execução de alguns serviços e funcionalidades, a <strong>GIGABYTE TELECOM</strong> conta com
            parceiros, colaboradores e fornecedores, com todos eles são tomados as medidas de segurança
            com o objetivo de proteger a sua privacidade. Todos autorizados para o atendimento das
            finalidades informadas nesta política.</p>
          <p>A <strong>GIGABYTE TELECOM</strong> também poderá compartilhar os <strong>DADOS PESSOAIS</strong> com
            <strong>AUTORIDADES</strong> dentro das hipóteses de cumprimento de obrigação legal ou regulatória,
            administração públicas, cumprimento de contrato, realização por órgãos de pesquisa, proteção
            do crédito ou segurança do cliente/usuário. Nestes termos a <strong>GIGABYTE TELECOM</strong> irá
            compartilhar o mínimo de informação possível para atingir a sua finalidade, garantindo sempre
            que possível o anonimato dos dados pessoais.</p>

          <li className="cookies">ELIMINAÇÃO E ANONIMIZAÇÃO</li>
          <p>Para cumprir exigências legais estabelecidas pelos órgãos reguladores, com exceção de
            determinação judicial, não poderá ser eliminado ou anonimizado dados que sejam inerentes à
            prestação do serviço pela <strong>GIGABYTE TELECOM</strong>, como dados cadastrais, dados de
            cobrança, dados de localização e dados de tráfego.</p>

          <li className="cookies">CONSENTIMENTO</li>
          <p>Faça a leitura de toda a <strong>POLÍTICA DE PRIVACIDADE</strong> e só utilize o consentimento caso
            esteja de acordo com a presente política.</p>
          <p>O <strong>ASSINANTE</strong>, ao cadastrar-se, manifesta conhecer e poder exercitar seus direitos de cancelar
            seu cadastro, acessar e atualizar seus dados pessoais e garantir a veracidade das informações por
            ele disponibilizadas.</p>
          <p>O <strong>ASSINANTE</strong> tem direito de retirar o seu consentimento a qualquer momento, para tanto
            deve entrar em contato com nosso <strong>ENCARREGADO DE DADOS (Data Protection Officer) </strong>
             através do E-mail <strong>dpo@gigabytetelecom.com.br</strong> ou por correio enviando ao seguinte
            endereço: <strong>Avenida Bernardo Sayão, 171, Bairro Primavera, cidade de Jaraguá, Cep:
              76.330-000, Goiás</strong></p>

          <li className="cookies">SEGURANÇA DOS DADOS</li>
          <p>A <strong>GIGABYTE TELECOM</strong> se compromete com seus clientes/usuários não medindo esforços
            para proteger os dados pessoais e de informações, aplicando as medidas de proteção
            administrativa e técnica necessária e disponíveis à época.</p>
          <p>A colaboradores também tem o compromisso de zelar pela segurança dos seus dados pessoais e
            de respeitar esta <strong>POLÍTICA DE PRIVACIDADE</strong>, sob pena de sofrerem sanção disciplinar em
            caso de violação dessas normas</p>
          <p>A <strong>GIGABYTE TELECOM</strong> recomenda que você nunca revele ou compartilhe a sua senha com
            outras pessoas. Você é o <strong>ÚNICO</strong> responsável por manter a senha confidencial e por qualquer
            ação realizada através de sua conta no site ou serviço.</p>
          <p>A <strong>GIGABYTE TELECOM</strong> se compromete a divulgar para você e a <strong>ANPD –
            AUTORIDADE NACIONAL DE PROTEÇÃO DE DADOS PESSOAIS</strong>, qualquer incidente
            de segurança e quais as medidas aplicadas nesse caso.</p>

          <li className="cookies">ENCARREGADO DOS DADOS (DATA PROTECTION OFFICER – DPO)</li>
          <p>É o profissional indicado pela <strong>GIGABYTE TELECOM</strong> para atuar como canal de
            comunicação entre o controlador, os titulares dos dados e a <strong>AUTORIDADE NACIONAL DE
              DADOS (ANPD)</strong>.</p>
          <p>Na <strong>GIGABYTE TELECOM</strong>, a encarregada de proteção de dados <strong>(Data officer – DPO)</strong> é
            <strong> FABIANA MARTINS SILVA MELO</strong>, que poderá ser contatada através do E-mail:
            <strong> dpo@gigabytetelecom.com.br</strong>.</p>

          <li className="cookies">DAS ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</li>
          <p>Reservamos o direito de modificar essas <strong>POLÍTICAS DE PRIVACIDADE</strong> a qualquer
            momento, conforme a finalidade ou necessidade, tal para adequação e conformidade legal de
            disposição de Lei ou norma que tenha força jurídica equivalente, então, é recomendável que o
            cliente/usuário a revise com frequência.</p>

          <li className="cookies">INFORMAÇÕES DE CONTATO</li>
          <p>Se persistirem dúvidas sobre a nossa <strong>POLÍTICA DE PRIVACIDADE</strong>, não deixe de fazer
            contato conosco pelo E-mail: <strong>dpo@gigabytetelecom.com.br</strong>.
          </p>

          <p>Atenciosamente,
            Gigabyte Telecom
            AV. Bernardo Sayão N° 171, Jardim Primavera
            Jaraguá-GO, CEP: 76.330-000, 08000622200
            Data de Atualização: 01/08/2021</p>
        </ol>        
      </div>
    </>
  )
}
export default Privacidade;