import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import './GigaPlus.css';

// Importar as imagens
import Img1 from '../../../assets/canais/plus/0001-A&E_Network_logo.svg.png';
import Img2 from '../../../assets/canais/plus/0002-LogoAXN.png';
import Img3 from '../../../assets/canais/plus/0003-LogoAnimalPlanet HD.png';
import Img4 from '../../../assets/canais/plus/0004-Logo-Arte1.png';
import Img5 from '../../../assets/canais/plus/004-LogoBandSports_logo.svg.png';
import Img6 from '../../../assets/canais/plus/005-Logocnnbrasil.png';
import Img7 from '../../../assets/canais/plus/9.png';
import Img8 from '../../../assets/canais/plus/008-LogoChefTV.png';
import Img9 from '../../../assets/canais/plus/009-LogoCinebrasilTV.png';
import Img10 from '../../../assets/canais/plus/9.png';
import Img11 from '../../../assets/canais/plus/0012-Logodiscovery-kids-logo.png';
import Img12 from '../../../assets/canais/plus/13.png';
import Img13 from '../../../assets/canais/plus/17.png';
import Img14 from '../../../assets/canais/plus/0018-MtvHD_.png';
import Img15 from '../../../assets/canais/plus/18.png';
import Img16 from '../../../assets/canais/plus/0019-LogoPrimeBoxBrazil2019.png';
import Img17 from '../../../assets/canais/plus/0020-LogoRecordNewsHD.png';
import Img18 from '../../../assets/canais/plus/20.png';
import Img19 from '../../../assets/canais/plus/0021-LogoSony_Channel_Logo.png';
import Img20 from '../../../assets/canais/plus/0022-LogoTNT.png';
import Img21 from '../../../assets/canais/plus/22.png';
import Img22 from '../../../assets/canais/plus/0023-LogoWarner2018LA.png';
import Img23 from '../../../assets/canais/plus/23.png';
import Img24 from '../../../assets/canais/plus/25.png';
import Img25 from '../../../assets/canais/plus/26.png';
import Img26 from '../../../assets/canais/plus/28.png';
import Img27 from '../../../assets/canais/plus/30.png';
import Img28 from '../../../assets/canais/plus/32.png';
import Img29 from '../../../assets/canais/plus/33.png';
import Img30 from '../../../assets/canais/plus/37.png';
import Img31 from '../../../assets/canais/plus/38.png';
import Img32 from '../../../assets/canais/plus/39.png';
import Img33 from '../../../assets/canais/plus/0040_zoomoo_kids.png';
import Img34 from '../../../assets/canais/plus/0040-sabor_e_arte.png';
import Img35 from '../../../assets/canais/plus/40.png';
import Img36 from '../../../assets/canais/plus/40-nick_jr.png';
import Img37 from '../../../assets/canais/plus/0041-tbc.png';
import Img38 from '../../../assets/canais/plus/0042-cartoonito.png';
import Img39 from '../../../assets/canais/plus/42.png';
import Img40 from '../../../assets/canais/plus/0043-curta.png';
import Img41 from '../../../assets/canais/plus/43.png';
import Img42 from '../../../assets/canais/plus/44.png';
import Img43 from '../../../assets/canais/plus/46.png';
import Img44 from '../../../assets/canais/plus/52-tv_ratimbum.png';
import Img45 from '../../../assets/canais/plus/History.png';
// import Img46 from '../../../assets/canais/plus/re_22_espn2.png';
import Img47 from '../../../assets/canais/plus/re_23_espn_br.png';
import Img48 from '../../../assets/canais/plus/0015-LogoESPN_Extra_Brazil_logo.png';
// import Img49 from '../../../assets/canais/plus/re-29.png';
import Img50 from '../../../assets/canais/plus/re-clima-tempo-12.png';
// import Img51 from '../../../assets/canais/plus/29.png';



// Criar o array de imagens
const canais = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img11, Img12, Img13, Img14, Img15, Img16, Img17, Img18, Img19, Img20, Img21, Img22, Img23, Img24, Img25, Img26, Img27, Img28, Img29, Img30,	Img31,	Img32,	Img33,	Img34,	Img35,	Img36,	Img37,	Img38, Img39,	Img40,	Img41,	Img42,	Img43,	Img44,	Img45,	Img47,	Img48, Img50];

const GigaPlus = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="canais-list">
      <div className="canais-list-title"><h1>Canais Giga Plus</h1></div>
        {canais.map((canal, index) => (
          <div key={index} className="canal-item">
            <img src={canal} alt={`Canal ${index + 1}`} className="canal-image" />
          </div>
        ))}
      </div>

    </>
  );
}

export default GigaPlus;