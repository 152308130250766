import './Snowflake.css';

const Snowflake = (props) => {
  return (
    <p className="Snowflake" id={`item${props.id}`} style={props.style}>
      *
      {/* ❄ */}
      {/* <i class="fa fa-snowflake-o" aria-hidden="true"></i> */}
    </p>
  );
};

export default Snowflake;
