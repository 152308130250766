import { useEffect } from 'react';

const AppRedirectPlay = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // Redireciona para Google Play
      window.location.href = 'https://play.google.com/store/apps/details?id=com.gigabytemobile&hl=pt_BR&pli=1';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redireciona para App Store
      window.location.href = 'https://apps.apple.com/br/app/gigabyte-play/id6443457059?l=pt';
    } else {
      // Dispositivo não suportado, redireciona para a página inicial
      window.location.href = '/';
    }
  }, []);

  return null; // Este componente não precisa renderizar nada
};

export default AppRedirectPlay;
