import React, { useState, useEffect } from "react";
import axios from "axios";

import './styles.css';
import Planos from './Planos/Planos';
import SeparatorBar from "../../components/SeparatorBar/SeparatorBar";
import AppGigaClient from "../../components/AppGigaClient/AppGigaClient";
import FlipBox from "../../components/FlipBox/FlipBox";

const InternetPage = () => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiImg = process.env.REACT_APP_API_IMG;

    const [internet, setInternet] = useState(null);

    useEffect(() => {
        fetchInternet();
    }, []);

    const fetchInternet = async () => {
        try {
            const response = await axios.get(`${apiUrl}/internets`);

            // Se houver dados na resposta, selecione aleatoriamente um item do array
            if (response.data.length > 0) {
                const internet = response.data[Math.floor(Math.random() * response.data.length)];
                setInternet(internet);
            }
        } catch (error) {
            console.error("Erro ao buscar o internet aleatório:", error);
        }
    };

    return (
        <>
            <div className="row">
                {internet && (
                    <>
                        <div className="assinar">
                            <img src={`${apiImg}/assets/internets/${internet.foto}`} alt="call center" />
                        </div>
                    </>
                )}
            </div>

            {/* <AnimatedCard /> */}

            <Planos />
            <SeparatorBar />
            <AppGigaClient />
            <SeparatorBar /><br /><br />
            {/* <Newsfeed /> */}
            <FlipBox />
            <br /><br />
        </>

    )
}

export default InternetPage;
