import React from 'react';


import './styles.css'; // Importando o arquivo de estilos
import CardPrice from '../../components/ComponentPhone/CardPrice/CardPrice';
import Faq from '../../components/ComponentPhone/Faq/Faq';
import CardImageIn from '../../components/ComponentPhone/CardImageIn/CardImageIn';


const Telefone = () => {
  return (
    <>
      <CardImageIn />
      <CardPrice />
      <Faq />
    </>
  );
};

export default Telefone;
