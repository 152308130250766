import React, { useState, useEffect } from "react";
import CarouselSlide from "../../components/CarouselSlide";
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import CardHome from "../../components/CardHome";
import CardRural from "../../components/ComponentInternetRural/CardRural";
import CardInternet from "../../components/ComponentInternetFibra/CardInternet";
import FlipBox from "../../components/FlipBox/FlipBox";
import CardImage from "../../components/ComponentPhone/CardImage/CardImage";
import { useMediaQuery } from 'react-responsive';
import Separator from "../../components/ComponentPhone/Separator/Separator";
import SeparatorBar from "../../components/SeparatorBar/SeparatorBar";
import FotoSlide from "../../components/FotoSlide/FotoSlide";
import SnowEffect from "../Snow/Snow";

import './styles.css';
import ListaCurriculos from "../CurriculoView/ListaCurriculos";
import HalloweenSpider from "../HalloweenSpider/HalloweenSpider";
import HalloweenGhosts from "../HalloweenSpider/HalloweenGhosts";
import HalloweenEyes from "../HalloweenSpider/Eyes";
import WitchFlying from "../HalloweenSpider/WitchFlying";
import AnimatedCard from "../../components/AnimatedCard";
import Christmas from "../Christmas/Christmas";
import Produtos from "../Produtos/Produtos";
import ChristmasStar from "../Christmas/ChristmasStar";

const Home = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
        // Configure o scrollSpy para rastrear os elementos AnimatedCard
        scrollSpy.update();
    }, []);

    return (
        <>
            <SnowEffect />
            <Christmas />
            <ChristmasStar />
            {isMobile ? <FotoSlide /> : <CarouselSlide />}
            <CardInternet />
            <Separator />
            <div className="container">
                <CardImage />
            </div>
            <CardHome />
            <CardRural />
            <SeparatorBar />
            <br /><br />
            <div className="container">
                {/* <AnimatedCard /> */}
                <Produtos />
            </div>
            <FlipBox />
            <br /><br />
            {/* <ListaCurriculos /> */}
            {/* <HalloweenSpider />
            <HalloweenGhosts />
            <HalloweenEyes />
            <WitchFlying /> */}
        </>
    )
}
export default Home;