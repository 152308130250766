import React from "react";
import './Snow.css';
import Snowflake from '../../components/Snowflake/Snowflake';

class SnowEffect extends React.Component {
  snow = () => {
    const numFlakes = 100; // Número de flocos de neve
    let animationDelay = '0s';
    let fontSize = '100px';
    
    return Array.from({ length: numFlakes }).map((_, i) => {
      animationDelay = `${(Math.random() * 16).toFixed(2)}s`;
      fontSize = `${(Math.floor(Math.random() * 20) + 10)}px`; // Tamanho entre 10px e 30px
      let horizontalPosition = `${Math.random() * 100}vw`; // Posição horizontal aleatória
      let style = {
        animationDelay,
        fontSize,
        left: horizontalPosition, // Define a posição inicial horizontal
      };
      return (<Snowflake key={i} id={i} style={style} />);
    });
  };

  render() {
    return (
      <div className='SnowEffect'>
        {this.snow()}
      </div>
    );
  }
}

export default SnowEffect;
