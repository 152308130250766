import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, Button, FormControl, FormGroup, FormCheck } from 'react-bootstrap';

//ICONS
import { ReactComponent as EnvelopeIcon } from '../../assets/img/envelope.svg';
import { ReactComponent as Calendar } from '../../assets/img/calendario.svg';
import { ReactComponent as Formation } from '../../assets/img/iconmonstr-task-list-square-filled.svg';
import { ReactComponent as LinkedIn } from '../../assets/img/linkedin.svg';
import { ReactComponent as Github } from '../../assets/img/github.svg';
import { ReactComponent as House } from '../../assets/img/iconmonstr-home-6.svg';
import { ReactComponent as User } from '../../assets/img/iconmonstr-user-5.svg';
import { ReactComponent as Phone } from '../../assets/img/phone.svg';
import { ReactComponent as Education } from '../../assets/img/education-svgrepo-com.svg';
import { ReactComponent as Instagram } from '../../assets/img/instagram.svg';
import { ReactComponent as Facebook } from '../../assets/img/facebook.svg';
import { ReactComponent as Twitter } from '../../assets/img/x_icon.svg';
import { ReactComponent as Photo } from '../../assets/img/photo.svg';
import Work from '../../assets/img/Daco_4335411.png';

import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

const Formulario = () => {
  const [nome, setNome] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [formatoValido, setFormatoValido] = useState(true);
  const [email, setEmail] = useState('');
  const [foto, setFoto] = useState(null);
  const [endereco, setEndereco] = useState('');
  const [telefone, setTelefone] = useState('');
  const [perfil, setPerfil] = useState('');
  const [experiencias, setExperiencias] = useState(['']);
  const [formacoes, setFormacoes] = useState(['']);
  const [cursos, setCursos] = useState(['']);

  // Novo estado para áreas de interesse (switches)
  const [cidadesInteresse, setCidadesInteresse] = useState({
    goiania: false,
    jaragua: false,
    ceres: false,
    uruana: false,
    uruacu: false
  });

  // Novo estado para áreas de interesse (switches)
  const [areasInteresse, setAreasInteresse] = useState({
    ti: false,
    administrativa: false,
    callCenter: false,
    atendimentoPublico: false,
    vendedor: false
  });

  const [redesSociais, setRedesSociais] = useState([{ tipo: '', url: '' }]);

  // Função para lidar com a alteração dos switches
  const handleAreaInteresseChange = (area) => {
    setAreasInteresse(prevState => ({
      ...prevState,
      [area]: !prevState[area]
    }));
  };

  // Função para lidar com a alteração dos switches
  const handleCidadeInteresseChange = (cidade) => {
    setCidadesInteresse(prevState => ({
      ...prevState,
      [cidade]: !prevState[cidade]
    }));
  };

  // Função para adicionar mais campos de cursos
  const adicionarCurso = () => {
    setCursos([...cursos, '']);
  };

  // Função para adicionar mais campos de experiência
  const adicionarExperiencia = () => {
    setExperiencias([...experiencias, '']);
  };

  // Função para adicionar mais campos de formação
  const adicionarFormacao = () => {
    setFormacoes([...formacoes, '']);
  };

  // Função para alterar as entradas de experiência
  const handleCursoChange = (index, event) => {
    const novasCursos = [...cursos];
    novasCursos[index] = event.target.value;
    setCursos(novasCursos);
  };

  // Função para alterar as entradas de experiência
  const handleExperienciaChange = (index, event) => {
    const novasExperiencias = [...experiencias];
    novasExperiencias[index] = event.target.value;
    setExperiencias(novasExperiencias);
  };

  // Função para alterar as entradas de formação
  const handleFormacaoChange = (index, event) => {
    const novasFormacoes = [...formacoes];
    novasFormacoes[index] = event.target.value;
    setFormacoes(novasFormacoes);
  };

  // Função para remover uma experiência
  const removerCurso = (index) => {
    const novasCursos = [...cursos];
    novasCursos.splice(index, 1);
    setCursos(novasCursos);
  };

  // Função para remover uma experiência
  const removerExperiencia = (index) => {
    const novasExperiencias = [...experiencias];
    novasExperiencias.splice(index, 1);
    setExperiencias(novasExperiencias);
  };

  // Função para remover uma formação
  const removerFormacao = (index) => {
    const novasFormacoes = [...formacoes];
    novasFormacoes.splice(index, 1);
    setFormacoes(novasFormacoes);
  };

  const handleRedeSocialChange = (index, field, value) => {
    const novasRedes = [...redesSociais];
    novasRedes[index][field] = value;
    setRedesSociais(novasRedes);
  };

  const adicionarRedeSocial = () => {
    setRedesSociais([...redesSociais, { tipo: '', url: '' }]);
  };

  const removerRedeSocial = (index) => {
    const novasRedes = redesSociais.filter((_, i) => i !== index);
    setRedesSociais(novasRedes);
  };

  const handleNomeChange = (event) => {
    setNome(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFotoChange = (event) => {
    setFoto(event.target.files[0]);
  };

  const handleEnderecoChange = (event) => {
    setEndereco(event.target.value);
  };

  const handleTelefoneChange = (event) => {
    setTelefone(event.target.value);
  };

  const handlePerfilChange = (event) => {
    setPerfil(event.target.value);
  };

  const handleNascimentoChange = (event) => {
    const inputDate = event.target.value;
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;

    setNascimento(inputDate);

    if (regex.test(inputDate)) {
      setFormatoValido(true);
    } else {
      setFormatoValido(false);
    }
  };

  const handleInvalidFormatFocus = (event) => {
    // Dê foco ao campo quando o formato for inválido
    if (!formatoValido) {
      event.target.focus();
    }
  };

  const [redirecionarParaDetalhes, setRedirecionarParaDetalhes] = useState(false);
  const [idDoCurriculoCadastrado, setIdDoCurriculoCadastrado] = useState('');


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formatoValido) {
      toast.error('Formato de data inválido. Insira a data no formato dd/mm/aaaa.', { position: 'top-right' });
      return;
    }

    const formData = new FormData();
    formData.append('nome', nome);
    formData.append('email', email);
    formData.append('foto', foto);
    formData.append('endereco', endereco);
    formData.append('telefone', telefone);
    formData.append('perfil', perfil);
    formData.append('experiencias', JSON.stringify(experiencias)); // Lista de experiências
    formData.append('formacoes', JSON.stringify(formacoes)); // Lista de formações
    formData.append('redesSociais', JSON.stringify(redesSociais)); // Redes sociais como array de objetos
    formData.append('cursos', JSON.stringify(cursos)); // Cursos como array de strings
    formData.append('areasInteresse', JSON.stringify(areasInteresse)); // Áreas de interesse como objeto
    formData.append('cidadesInteresse', JSON.stringify(cidadesInteresse)); // Áreas de interesse como objeto

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/curriculos`, formData);
      const idDoCurriculoCadastrado = response.data._id;
      setIdDoCurriculoCadastrado(idDoCurriculoCadastrado);

      toast.success('Currículo cadastrado com sucesso!', { position: 'top-right' });
      setRedirecionarParaDetalhes(true);
    } catch (error) {
      console.error('Erro ao cadastrar o currículo:', error);
      toast.error('Erro ao cadastrar o currículo.', { position: 'top-right' });
    }
  };


  console.log('redirecionarParaDetalhes:', redirecionarParaDetalhes); // Debug
  console.log('idDoCurriculoCadastrado:', idDoCurriculoCadastrado); // Debug


  return (
    <div className='container'>
      {redirecionarParaDetalhes && idDoCurriculoCadastrado && <Navigate to={`/curriculo/${idDoCurriculoCadastrado}`} />}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <div className='row'>
            <div className='orientacao'>
              <div className='saudacao'>
                <span>
                  Olá futuro colaborador da Gigabyte Telecom! Estamos animados para conhecê-lo melhor. Por favor, preencha o formulário abaixo para nos contar mais sobre suas experiências, habilidades e personalidade.
                </span>
              </div>

              <hr />
            </div>
          </div>

          <div className='row'>
            <h5>Informações de Identificação</h5>
            <div className='col-sm-12 col-md-4'>
              <Form.Label htmlFor="nome"> Nome Completo:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><User /></span>
                <FormControl type="text" placeholder="Digite o nome" id="nome" value={nome} onChange={handleNomeChange} required />
              </div>
              <p>Por favor, informe seu nome completo.</p>
            </div>
            <div className='col-sm-12 col-md-4'>
              <Form.Label htmlFor="datanascimento">Data de Nascimento:</Form.Label>
              <div className={`text-icon ${formatoValido ? '' : 'campo-invalido'}`}>
                <span className="input-group-text" id="addon-wrapping"><Calendar /></span>
                <FormControl
                  type="text"
                  placeholder="Digite sua data de nascimento"
                  id="nascimento"
                  value={nascimento}
                  onChange={handleNascimentoChange}
                  className={formatoValido ? '' : 'formato-invalido'} // Adiciona classe para feedback visual
                />
              </div>
              <p>Por favor, informe a data no formato dd/mm/aaaa.</p>
              {!formatoValido && <p className="mensagem-erro">Insira sua data de nascimento no formato dd/mm/aaaa.</p>}
            </div>
            <div className='col-sm-12 col-md-4'>
              <Form.Label htmlFor="email">E-mail:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><EnvelopeIcon /></span>
                <FormControl type="email" placeholder="Digite seu e-mail" id="email" value={email} onChange={handleEmailChange} />
              </div>
              <p>Insira seu endereço de e-mail para que possamos entrar em contato.</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-4'>
              <Form.Label htmlFor="endereco">Endereço:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><House /></span>
                <FormControl type="text" placeholder="Endereço" id="endereco" value={endereco} onChange={handleEnderecoChange} required />
              </div>
              <p>Compartilhe seu endereço atual.</p>
            </div>

            <div className='col-sm-12 col-md-4'>
              <Form.Label htmlFor="endereco">Telefone:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Phone /></span>
                <FormControl type="text" placeholder="Telefone" id="telefone" value={telefone} onChange={handleTelefoneChange} required />
              </div>
              <p>Informe um número de telefone para facilitar nossa comunicação.</p>
            </div>

            <div className='col-sm-12 col-md-4'>
              <label htmlFor="foto">Foto:</label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Photo /></span>
                <FormControl type="file" id="foto" onChange={handleFotoChange} required />
              </div>
              <p>Anexe uma foto sua para que possamos reconhecê-lo. (Dica: Opte por uma foto profissional.)</p>

            </div>
          </div>
          <br /><hr /><br />
          <div className='row'>
            <h5>Informe a sua Formação Acadêmica</h5>
            <div className='col-sm-6 mb-3'>
              <Form.Label htmlFor="formacao">Formação Escolar:</Form.Label>
              {formacoes.map((formacao, index) => (
                <div key={index} className='text-icon'>
                  <span className="input-group-text" id="addon-wrapping"><Education /></span>
                  <FormControl
                    type="text"
                    placeholder="Digite a sua formação escolar"                    
                    value={formacao}
                    onChange={(e) => handleFormacaoChange(index, e)}
                    required
                  />
                  {formacoes.length > 1 && (
                    <Button variant="outline-danger" onClick={() => removerFormacao(index)}>Remover</Button>
                  )}
                </div>
              ))}
              <p>Descreva sua formação acadêmica, indicando cursos, instituições e datas.</p>
              <Button variant="outline-primary" size='sm' onClick={adicionarFormacao}>
                <i class="fa fa-plus" aria-hidden="true" />  Formação
              </Button>
            </div>
            

            <div className='col-sm-12 col-md-6'>
              <Form.Label htmlFor="curso">Outros Cursos:</Form.Label>
              {cursos.map((curso, index) => (
                <div key={index} className='text-icon'>
                  <span className="input-group-text" id="addon-wrapping"><Formation /></span>
                  <FormControl
                    type="text"
                    placeholder="Curso profissional"
                    value={curso}
                    onChange={(e) => handleCursoChange(index, e)}
                    required
                  />
                  {cursos.length > 1 && (
                    <Button variant="outline-danger" size='sm' onClick={() => removerCurso(index)}>Remover</Button>
                  )}
                </div>
              ))}
              <p>Liste seus cursos profissionais anteriores. Inclua o nome das empresas, suas funções e as datas de início e término.</p>
              <Button variant="outline-primary" size='sm' onClick={adicionarCurso}>
                <i class="fa fa-plus" aria-hidden="true" /> Curso
              </Button>
            </div>
          </div>

          <div className='row teste-row'>
            <div className='col-sm-12 col-md-6 '>
              <Form.Label htmlFor="experiencia">Experiência Profissional:</Form.Label>
              {experiencias.map((experiencia, index) => (
                <div key={index} className='text-icon'>
                  <span className="input-group-text" id="addon-wrapping"><Formation /></span>
                  <FormControl
                    as='textarea'
                    placeholder="Experiência profissional"
                    rows={2}
                    value={experiencia}
                    onChange={(e) => handleExperienciaChange(index, e)}
                    required
                  />
                  {experiencias.length > 1 && (
                    <Button variant="outline-danger" onClick={() => removerExperiencia(index)}>Remover</Button>
                  )}
                </div>
              ))}
              <p>Liste suas experiências profissionais anteriores. Inclua o nome das empresas, suas funções e as datas de início e término.</p>
              <Button variant="outline-primary" size='sm' onClick={adicionarExperiencia}>
                <i class="fa fa-plus" aria-hidden="true" /> Experiência
              </Button>
            </div>

            <div className='col-sm-12 col-md-6 '>
              <Form.Label htmlFor="perfil">Perfil Pessoal:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><img src={Work} alt='foto' /></span>
                <FormControl as="textarea" placeholder="Qual o seu perfil pessoal, aptidões e habilidades" rows={2} value={perfil} onChange={handlePerfilChange} required />
              </div>
              <p>Conte-nos um pouco sobre quem você é, suas paixões e valores.</p>
            </div>
          </div>
          <br /><hr /><br />
          <div className='row'>
            <div className='col-sm-12 col-md-3'>
              {/* Seção de Áreas de Interesse */}
              <div className="mb-3">
                <h5>Qual sua Área de Interesse?</h5>
                <FormCheck
                  type="switch"
                  id="switch-ti"
                  label="Área Técnica TI"
                  checked={areasInteresse.ti}
                  onChange={() => handleAreaInteresseChange('ti')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-administrativa"
                  label="Área Administrativa"
                  checked={areasInteresse.administrativa}
                  onChange={() => handleAreaInteresseChange('administrativa')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-callcenter"
                  label="Call Center"
                  checked={areasInteresse.callCenter}
                  onChange={() => handleAreaInteresseChange('callCenter')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-atendimento-publico"
                  label="Atendimento ao Público"
                  checked={areasInteresse.atendimentoPublico}
                  onChange={() => handleAreaInteresseChange('atendimentoPublico')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-vendedor"
                  label="Vendedor"
                  checked={areasInteresse.vendedor}
                  onChange={() => handleAreaInteresseChange('vendedor')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
              </div>
            </div>

              {/* Cidades */}
            <div className='col-sm-12 col-md-3'>
              <div className="mb-3">
                <h5>Qual cidade deseja trabalhar?</h5>
                <FormCheck
                  type="switch"
                  id="switch-goiania"
                  label="Goiânia"
                  checked={cidadesInteresse.goiania}
                  onChange={() => handleCidadeInteresseChange('goiania')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-jaragua"
                  label="Jaraguá"
                  checked={cidadesInteresse.jaragua}
                  onChange={() => handleCidadeInteresseChange('jaragua')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-ceres"
                  label="Ceres"
                  checked={cidadesInteresse.ceres}
                  onChange={() => handleCidadeInteresseChange('ceres')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-uruana"
                  label="Uruana"
                  checked={cidadesInteresse.uruana}
                  onChange={() => handleCidadeInteresseChange('uruana')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
                <FormCheck
                  type="switch"
                  id="switch-uruacu"
                  label="Uruaçu"
                  checked={cidadesInteresse.uruacu}
                  onChange={() => handleCidadeInteresseChange('uruacu')}
                  style={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    gap: 5
                  }}
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-6">
              <h5>Informe suas Redes Sociais</h5>
              {/* <Form.Label htmlFor="redes-sociais">Redes Sociais:</Form.Label> */}
              {redesSociais.map((rede, index) => (
                <div key={index} className="text-icon mb-3">
                  <div className="select-input">
                    <div className='select-rede'>
                      <Form.Select
                        value={rede.tipo}
                        onChange={(e) => handleRedeSocialChange(index, 'tipo', e.target.value)}
                        required

                      >
                        <option value="">Selecione</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Github">Github</option>
                        <option value="Linkedin">Linkedin</option>
                      </Form.Select>
                    </div>
                    <div className='url-rede'>
                      <FormControl
                        type="url"
                        placeholder="URL da rede social"
                        value={rede.url}
                        onChange={(e) => handleRedeSocialChange(index, 'url', e.target.value)}
                        required

                      />
                    </div>
                    {redesSociais.length > 1 && (
                      <Button
                        variant="outline-danger"
                        onClick={() => removerRedeSocial(index)}
                      >
                        Remover
                      </Button>
                    )}
                  </div>
                </div>
              ))}
              <p>Adicione suas redes sociais, selecionando o tipo e fornecendo a URL correspondente.</p>

              <Button variant="outline-primary" size='sm' onClick={adicionarRedeSocial}>
                <i class="fa fa-plus" aria-hidden="true" /> Redes
              </Button>

            </div>
          </div>

          <div className='orientacao'>
            Observações:

            Certifique-se de preencher todas as informações com precisão.
            Seja transparente e autêntico nas suas respostas.
            Este é um espaço para nos mostrar o que o torna único, então sinta-se à vontade para adicionar detalhes que considere relevantes.
            Obrigado por dedicar seu tempo para preencher nosso formulário! Aguardamos ansiosamente para conhecer mais sobre você e explorar como sua jornada pode se alinhar à Gigabyte Telecom. Boa sorte!
          </div>
          <br />
          {/* {alertMessage && <Alert variant={alertVariant}>{alertMessage}</Alert>} */}
          <Button type="submit">Enviar</Button>
          <hr />
        </FormGroup>
      </Form>
    </div>
  );
};


export default Formulario;
