
const CookiesPage = () => {
  return (
    <div className="container">
      {/* <h2>Política de Cookies</h2> */}
        <ol  >
          <li className="cookies">OBJETIVO</li>
          <p>O objetivo da Política de Cookies é fornecer orientação de cookies no contexto de atuação da
            Melo Telecomunicações. A mesma se aplica a qualquer aplicativo, plataforma, redes sociais e
            websites.
          </p>
          <p>A Melo Telecomunicações sempre em busca da transparência vem através dessa política de
            cookies explicar, orientar e informar quais os cookies, quais objetivos e o porquê do uso.</p>

          <li className="cookies">DOCUMENTOS REFERENCIAS</li>
          <p>Lei 13.709, de 14 de agosto de 2018, Lei Geral de Proteção de Dados Pessoais.</p>

          <li className="cookies">O QUE É UM COOKIE</li>
          <p>Os Cookies são pequenos arquivos criados por sites visitados e que são salvos no computador
            do usuário, por meio do navegador ou aplicativo e que guardam informações relacionadas à sua
            preferência.</p>
          <p>
            Importante destacar que os cookies <strong>NÃO</strong>  são usados para determinar a identidade pessoal de
            quem está visitando o nosso site: www.gigabytetelecom.com.br.
          </p>

          <li className="cookies">POR QUE USAMOS COOKIE</li>
          <p>Para garantir aos nossos usuários uma melhor experiência de navegação ao visitar o site da
            Gigabyte Telecom.
          </p>

          <li className="cookies">QUAL O TIPO DE COOKIE QUE UTILIZAMOS?</li>
          <p> A Gigabyte Telecom utiliza doistipos de Cookies: <strong>Cookies de Sessão</strong> e Cookies <strong>Estritamente Necessários</strong></p>
          <p> <strong>Cookies de Sessão</strong>: São Cookies temporários que permanecem arquivados até que o usuário saia do site ou encerre o navegador ou aplicativo.</p>
          <p><strong>Importante destacar que os COOKIES DE SESSÃO NÃO coletam informações do seu computador. Eles normalmente armazenam informações e não coletam dados pessoais do usuário.</strong></p>
          <p><strong>Cookies Estritamente Necessários:</strong> Permite a navegação no website e a utilização de aplicações, bem como obter autorização de acesso a áreas seguras do website. Sem estes cookies, os serviços requeridos não podem ser prestados para o correto funcionamento da plataforma</p>

          <li className="cookies">COMO BLOQUEAR OU EXCLUIR UM COOKIE</li>
          <ol className="cookies-ol">
            <li>A maioria dos navegadores de Internet é configurado para aceitar automaticamente os
              cookies. Portanto, se você não quiser que cookies sejam utilizados, você talvez tenha que
              bloquear ou excluir cookies ativamente.</li>
            <li>Existem várias formas de gerenciar cookies. Consulte as instruções do seu navegador.</li>
            <li>Ao desativar Cookies, o usuário talvez não consiga visitar certas áreas de uma página nossa.</li>
            <li>Caso o usuário utilize dispositivos diferentes para acessar a nossa plataforma digital (Por exemplo, um computador, smartphone, tablets ou etc.) deverá assegurar-se de que cada navegador de cada dispositivo esteja ajustado para atender suas preferências em relação aos cookies.</li>
            <li> Ao utilizar nosso website sem bloquear ou excluir cookies, você concorda que podemos utilizar os Cookies de Sessão e Cookies Estritamente Necessários.</li>
          </ol>

          <li className="cookies">ALTERAÇÃO DA POLÍTICA DE COOKIE</li>
          <p>A Gigabyte Telecom se dá ao direito de alterar essa Política e Cookie sempre que se entender
            necessário. No caso de serem realizadas alterações na política, estas podem levar até 30 (Trinta)
            dias úteis para implementação de novas práticas.
            O usuário será informado sobre essa alteração ou ele deve, voluntariamente, acessar o site de
            forma periódica.</p>

          <li className="cookies">INFORMAÇÕES DE CONTATO</li>
          <p>Se persistirem dúvidas ou informação sobre nossa Política de Cookies, estamos disponíveis através dos contatos abaixo: E-mail: dpo@gigabytetelecom.com Telefone: 62 2200 0000</p>

          <p>Atenciosamente,
            Gigabyte Telecom
            AV. Bernardo Sayão N° 171, Jardim Primavera
            Jaraguá-GO, CEP: 76.330-000, 08000622200
            Data de Atualização: 01/08/2021</p>
        </ol>
    </div>
  )
}
export default CookiesPage;