import React, { useState, useEffect } from 'react';
import './ChristmasStar.css';
import ghostImage from '../../assets/gifs/1.gif';  // Substitua com a imagem PNG de fantasma

const Ghost = ({ delay, position }) => {
  const [showGhost, setShowGhost] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGhost(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <>
      {showGhost && (
        <div className={`ghost-container ${position}`}>
          <img src={ghostImage} className="ghost" alt="Ghost" />
        </div>
      )}
    </>
  );
};

const ChristmasStar = () => {
  return (
    <>
    <div className='image2'>
      <Ghost delay={500} position="left" />
    </div>
      <div className='image3'>
      <Ghost delay={1500} position="left2" />
      </div>
      <Ghost delay={2500} position="right" />
    </>
  );
};

export default ChristmasStar;
