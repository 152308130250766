import React from 'react';
import { FaCheck } from 'react-icons/fa';
import './GigabytePlay.css';

const CheckList = ({ items }) => {
  return (
    <ul className="check-list">
      {items.map((item, index) => (
        <li key={index}>
          <FaCheck className="check-icon" /> {item}
        </li>
      ))}
    </ul>
  );
};

export default CheckList;