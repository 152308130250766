import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import './IpPhone.css';
import CopywritingPhone from './CopywritingPhone';

const IpPhone = () => {
  const [cards, setCards] = useState([]);

  const TextRender = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/cardPhones`);
        setCards(response.data);
      } catch (error) {
        console.error('Erro ao buscar os cards:', error);
      }
    };

    fetchCards();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className="ipphone-service-title mb-4">              
              <CopywritingPhone />
            </div>
          </Col>
        </Row>
      </Container>
      {cards.map((card, index) => (
        <Container
          fluid
          key={index}
          className={index % 2 === 0 ? 'ipphone-service' : 'ipphone-service2'}
        >
          <Row>
            {/* Alternar entre imagem à direita e à esquerda */}
            {index % 2 === 0 ? (
              <>
                <Col md={7}>
                  <div className="text-phone-title-list mb-4">
                    <h1 className="mb-4">{card.title}</h1>
                    {card.list && <p>{<TextRender htmlContent={card.list} />}</p>}    
                    {card.text && <div className='card-text'>{<TextRender htmlContent={card.text} />}</div>}
                    {card.obs && <div className='card-obs'>{<TextRender htmlContent={card.obs} />}</div>}
                  </div>
                </Col>
                <Col md={5}>
                  <img
                    src={`${process.env.REACT_APP_API_IMG}/assets/cardPhones/${card.foto}`}
                    alt={card.title}
                    className="img-fluid"
                  />
                </Col>
              </>
            ) : (
              <>
                <Col md={5}>
                  <img
                    src={`${process.env.REACT_APP_API_IMG}/assets/cardPhones/${card.foto}`}
                    alt={card.title}
                    className="img-fluid"
                  />
                </Col>
                <Col md={7}>
                  <div className="text-phone-title-list mb-4">
                    <h1 className="mb-4">{card.title}</h1>
                    {card.list && <p>{<TextRender htmlContent={card.list} />}</p>}                
                    {card.text && <div className='card-text'>{<TextRender htmlContent={card.text} />}</div>}
                    {card.obs && <div className='card-obs'>{<TextRender htmlContent={card.obs} />}</div>}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Container >
      ))}
    </>
  );
};

export default IpPhone;
