import React from 'react';

import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import IpPhoneImage from '../../../assets/images/ipphone.png';
import IpPhoneImage2 from '../../../assets/images/ipphone2.png';
import Phone from '../../../assets/images/phone.png';
import PhoneIcon from '../../../assets/icons/phone.png';

import './CardPrice.css';
import SeparatorBar from '../../SeparatorBar/SeparatorBar';
import Plans from '../Plans/Plans';
import IpPhone from '../IpPhone/IpPhone';

const CardPrice = () => {
  return (
    <>      
      <IpPhone />
      <Plans />
      <SeparatorBar />
    </>
  )
}
export default CardPrice;