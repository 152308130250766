import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import SeparatorBar from '../../SeparatorBar/SeparatorBar';
import ContactForm from '../../../components/ContactForm/ContactForm';

import './RuralInternetPlan.css';


const RuralInternetPlan = () => {

  const title = 'Formulário de contato'

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showChannels, setShowChannels] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPlanData, setSelectedPlanData] = useState(null);

  const handleShowChannels = (title) => {
    setSelectedPlan(title);
    setShowChannels(true);
  };

  const handleClose = () => {
    setShowMoreInfo(false);
    setShowChannels(false);
  };

  const [copywritingsRurals, setCopywritingsRurals] = useState([]);

  const TextRender = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  useEffect(() => {
    fetchCopywritingsRurals();
  }, []);

  const fetchCopywritingsRurals = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/copywritingsRural`);
      setCopywritingsRurals(response.data);
    } catch (error) {
      console.error("Erro ao buscar os CopywritingPhone:", error);
    }
  };

  return (
    <>
      <div className='rural-plan'>
        <Container className="rural-internet-plan my-5">
          <br />
          {copywritingsRurals[1] && ( // Verifica se há pelo menos um item no array
            <div key={copywritingsRurals[0]._id}>
              <div>
                <h1 className="text-center-rural mb-4">{copywritingsRurals[0].title}</h1>
              </div>

              <Row className="justify-content-center">
                <Col md={12}>
                  <Card className="plan-card text-center">
                    <Card.Body>
                      <Card.Text>
                        <h2>{copywritingsRurals[0].sub}</h2>
                        <SeparatorBar />
                      </Card.Text>
                      <Card.Text className="lead">
                      <TextRender htmlContent={copywritingsRurals[0].text} />
                      </Card.Text>
                      <Button variant="primary" className="btn-signup" onClick={() => handleShowChannels(title)}>CONTRATE AGORA</Button>
                      <p className="text-muted mt-3">Clique em assinar para verificar a disponibilidade para sua localidade.</p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </Container>
        <br />

        {/* Modal para Ver Canais */}
        <Modal show={showChannels} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Formulário de Contato</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ContactForm />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default RuralInternetPlan;
