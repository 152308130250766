import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './RuralInternet.css';
// import ruralImage from '../../../assets/images/4GLTE-Photoroom.png';
import SeparatorBar from '../../SeparatorBar/SeparatorBar';

const RuralInternet = () => {

  const [copywritingsRurals, setCopywritingsRurals] = useState([]);

  const TextRender = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  useEffect(() => {
    fetchCopywritingsRurals();
  }, []);

  const fetchCopywritingsRurals = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/copywritingsRural`);
      setCopywritingsRurals(response.data);
    } catch (error) {
      console.error("Erro ao buscar os CopywritingPhone:", error);
    }
  };

  return (
    <>
      <SeparatorBar />
      <Container className="rural-internet">
        {copywritingsRurals[1] && ( // Verifica se há pelo menos um item no array
          <div key={copywritingsRurals[1]._id}>
            <div className="title-rural">
            <h1>{copywritingsRurals[1].title}</h1>
            <h6>{copywritingsRurals[1].sub}</h6>
            </div>
            <Row className="align-items-center">
              <Col md={12}>
                <Card className="rural-card">
                  <Card.Body>
                    <Card.Text className="lead">
                      <TextRender htmlContent={copywritingsRurals[1].text} />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </Container>

    </>
  );
};

export default RuralInternet;
