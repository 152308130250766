import React from 'react';
import { Container, Accordion } from 'react-bootstrap';
import './Faq.css'; // Importando o arquivo de estilos

const Faq = () => {
  return (
    <Container className="faq my-5">
      <h1 className="text-center mb-4">Dúvidas Frequentes</h1>
      <Accordion className='text-accordion'>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Quanto tempo leva para mudar meu número para a Giga Fixo?</Accordion.Header>
          <Accordion.Body>
            O processo de portabilidade numérica geralmente ocorre no terceiro dia após o registro do pedido pela Gigabyte Telecom, desde que não ocorra nenhum impedimento de acordo com as regras do processo de portabilidade.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>É necessário entrar em contato com a operadora atual para solicitar portabilidade para a Giga Fixo?</Accordion.Header>
          <Accordion.Body>
            Não, o cliente deve se dirigir a uma das lojas da Gigabyte Telecom portando documento com foto e a última fatura paga da antiga operadora e a Gigabyte se encarregará dos demais processos.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>A portabilidade é cobrada?</Accordion.Header>
          <Accordion.Body>
            O regulamento da Anatel autoriza a operadora para a qual o cliente está mudando a cobrar taxa para portabilidade, mas clientes que vierem para a Gigabyte estarão isentos da cobrança.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>É necessário ter um tempo mínimo na outra operadora para solicitar portabilidade para a Gigabyte?</Accordion.Header>
          <Accordion.Body>
            Não. Você pode solicitar a portabilidade a qualquer momento, sem obrigatoriedade de tempo mínimo em outra operadora.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Contratando o plano de telefonia eu terei fidelidade?</Accordion.Header>
          <Accordion.Body>
            Adesão 100% gratuita.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className='obs-phone'>
      O cliente pode realizar o referido pagamento no dia de vencimento da mensalidade. 
      Para emissão de segunda via de boleto, por gentileza, entrar em contato pelos telefones 
      (62) 2200-0000 | (62) 2203-0000 ou pelo e-mail faleconosco@gigabytetelecom.com.br. 
      Certo de sua compreensão desde já agradecemos. Estamos á disposição para maiores esclarecimentos. 
      Oferta exclusiva para clientes Gigabyte Telecom, 
      consulte condições de disponibilidade e instalação para novos clientes.
      </div>
    </Container>
  );
};

export default Faq;
