import { IconTextColor } from "@tabler/icons-react";
import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa";

const PdfList = () => {
  const [pdfFiles, setPdfFiles] = useState([]);

  const handleDownload = (fileName) => {
    const link = document.createElement("a");
    link.href = `/assets/pdf/${fileName}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const iconStyle = {
    color: '#D00000',       // Define a cor do ícone
    fontSize: '24px',   // Define o tamanho do ícone
    marginRight: '8px', // Define margem à direita, se necessário
  };

  // Carrega os arquivos PDF na montagem do componente
  useEffect(() => {
    // Lista de arquivos PDF - substitua pelos nomes reais dos arquivos ou carregue dinamicamente se possível
    const files = [
      { name: "Contrato Adesao Tv", fileName: "ContratoAdesaoTv.pdf" },
      { name: "Contrato Comodato Registrado", fileName: "ContratoComodatoRegistrado.pdf" },
      { name: "Contrato de Comodato Cliente", fileName: "ContratoDeComodatoCliente.pdf" },
      { name: "Contrato de SCM2019", fileName: "ContratodeSCM2019.pdf" },
      { name: "Contrato de STFC Registrado Atual", fileName: "ContratodeSTFCRegistradoAtual.pdf" },
      { name: "Contratode Telefone Fixo", fileName: "ContratodeTelefoneFixo.pdf" },
      { name: "Contrato Goiania Aparecida", fileName: "ContratoGoianiaAparecida.pdf" },
      { name: "Contrato Jaragua Etc", fileName: "ContratoJaraguaEtc.pdf" },
      { name: "Contrato Permanência", fileName: "ContratoPermanência.pdf" },
      { name: "Contrato SCM 4Gltde Registrado Atual", fileName: "ContratoSCM4GltdeRegistradoAtual.pdf" },
      { name: "Contrato SCM Registrado", fileName: "ContratoSCMRegistrado.pdf" },
      { name: "Termo de Alteração de Data de Mensalidade", fileName: "TermodeAlteracaodeDatadeMensalidade.pdf" },
      { name: "Termo de Transferencia de Titularidade de Telefone Fixo", fileName: "TermodeTransferenciadeTitularidadedeTelefoneFixo.pdf" },
      { name: "Termo Alteração Plano Goiania", fileName: "TermoAlteracaoPlanoGoiania.pdf" },
      { name: "Termo Alteração Plano Jaraguá Ceres", fileName: "TermoAlteracaoPlanoJaraguaCeres.pdf" },
      { name: "Termo Mudanca Endereco Goiania", fileName: "TermoMudancaEnderecoGoiania.pdf" },
      { name: "Termo Mudanca Endereco JC", fileName: "TermoMudancaEnderecoJC.pdf" },
      { name: "Termo Troca Equipamentos", fileName: "TermoTrocaEquipamentos.pdf" },
      // Adicione mais PDFs conforme necessário
    ];

    setPdfFiles(files);
  }, []);

  return (
    <div className="container my-4">
      <h2 className="mb-4">Contratos</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nome do Contrato</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {pdfFiles.map((pdf, index) => (
            <tr key={index}>
              <td><FaRegFilePdf style={iconStyle} /> {pdf.name}</td>
              <td>                
                <Button
                  variant="outline-success"
                  href={`/pdf/${pdf.fileName}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEye />
                </Button>{' '}
                <Button
                  variant="outline-primary"
                  onClick={() => handleDownload(pdf.fileName)}
                  className="mr-2"
                >
                  <FaDownload />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PdfList;
