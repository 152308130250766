import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from 'react-bootstrap/Carousel';
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import FormControl from "react-bootstrap/FormControl";
// import FormGroup from "react-bootstrap/FormGroup";

// import CarouselImage from '../../assets/images/foto_1693160879915.jpg';
// import CarouselImage2 from '../../assets/images/foto_1693160911613.jpg';
// import CarouselImage3 from '../../assets/images/foto_1693162472210.jpg';

import './styles.css';

const CarouselSlide = () => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiImg = process.env.REACT_APP_API_IMG;

    const [carousels, setCarousels] = useState([]);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchCarousels();
    }, []);

    const fetchCarousels = async () => {
        try {
            const response = await axios.get(`${apiUrl}/carousels`);
            setCarousels(response.data);
        } catch (error) {
            console.error("Erro ao buscar os carousels:", error);
        }
    };

    return (
        <>
            {/* <Carousel >
                <Carousel.Item>
                    <img src={CarouselImage} />
                    <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={CarouselImage2} />
                    <Carousel.Caption>
                        <h3>Second slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={CarouselImage3} />
                    <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <hr /> */}
            <Carousel >
                {carousels.map((carousel) => (
                    <Carousel.Item>
                        <div key={carousel._id} >
                            <Carousel.Caption>
                                {/* <div className="card-carousel-title">
                                    <h3>{carousel.title}</h3>
                                    <span>{carousel.sub}</span>
                                    <p>
                                        <TextRender htmlContent={carousel.text} />
                                    </p>
                                </div> */}
                            </Carousel.Caption>
                            <div className="card-carousel-img">
                                <img src={`${apiImg}/assets/carousels/${carousel.foto}`} alt="Carousel" />
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </>
    );
}
export default CarouselSlide;