// src/components/Produtos/Produtos.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Produtos.css';
import CategoryList from './CategoryList/CategoryList';
import CategoryTabs from './CategoryTabs/CategoryTabs';

const Produtos = () => {
  const [produtos, setProdutos] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiImg = process.env.REACT_APP_API_IMG;

  useEffect(() => {
    fetchProdutos();
  }, []);

  const fetchProdutos = async () => {
    try {
      const response = await axios.get(`${apiUrl}/produtos`);
      setProdutos(response.data);
    } catch (error) {
      console.error('Erro ao buscar os produtos:', error);
    }
  };

  const TextRender = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  return (
    // <Container>
    //   <h2 className="produtos-title">Produtos Homologados</h2>
    //   <Row>
    //     {produtos.map((produto) => (
    //       <Col key={produto._id} sm={12} md={6} lg={4} className="mb-4">
    //         <Card className="produto-card">
    //           <Card.Img variant="top" src={`${apiImg}/assets/produtos/${produto.foto}`} alt={produto.nome} />
    //           <Card.Body>
    //             <Card.Title>{produto.nome}</Card.Title>
    //             <Card.Text>
    //               <TextRender htmlContent={produto.descricao} />
    //             </Card.Text>
    //             <Card.Text className="produto-preco">
    //               Preço: R$ {parseFloat(produto.preco).toFixed(2)}
    //             </Card.Text>
    //             <Button variant="primary" className="custom-button">Saiba mais</Button>
    //           </Card.Body>
    //         </Card>
    //       </Col>
    //     ))}
    //   </Row>
    // </Container>
    <CategoryTabs />
  );
};

export default Produtos;
