import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import Wifi from '../../../assets/images/www.png';
import INTERNET from '../../../assets/images/www.png';
import ContactForm from '../../../components/ContactForm/ContactForm';
import axios from 'axios'; // Importa o axios para fazer a requisição à API

import './Planos.css';

const Planos = () => {
  const [plans, setPlans] = useState([]); // Guarda os planos obtidos da API
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showChannels, setShowChannels] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPlanData, setSelectedPlanData] = useState(null);

  // Fetch plans from API on component mount
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/planoInternets`);
        setPlans(response.data); // Seta a lista de planos obtida da API
      } catch (error) {
        console.error('Erro ao buscar planos:', error);
      }
    };
    fetchPlans();
  }, []);

  const handleShowMoreInfo = (plan) => {
    setSelectedPlan(plan.plans);
    setSelectedPlanData(plan);
    setShowMoreInfo(true);
  };

  const handleShowChannels = (plan) => {
    setSelectedPlan(plan.plans);
    setShowChannels(true);
  };

  const handleClose = () => {
    setShowMoreInfo(false);
    setShowChannels(false);
  };

  return (
    <>
      <Container className="app-giga-play">
        <div className="text-center-title">Planos de Internet Fibra Óptica</div>
        <p>
          Desfrute da nossa Internet Fibra: velocidade e confiabilidade incomparáveis para sua experiência online perfeita. Navegue, assista e trabalhe sem interrupções.
        </p>
        <Row className="my-4">
          {plans.map((plan, index) => (
            <Col key={index} md={3} sm={6}>
              <Card className="internet-card">
                <Card.Img variant="top" src={Wifi} alt={plan.plans} className="internet-image" />
                <Card.Header>Internet Fibra Óptica</Card.Header>
                <Card.Body>
                  <Card.Title>{plan.plans}</Card.Title>
                  <Card.Text className='internet-body'>
                    {/* <strong><i className="fa fa-plus-square-o" aria-hidden="true"></i></strong> {plan.description}<hr /> */}
                    <strong><i className="fa fa-download" aria-hidden="true"></i></strong> {plan.dowload}<hr />
                    <strong><i className="fa fa-upload" aria-hidden="true"></i></strong> {plan.upload}<hr />
                    <h2><strong>R$</strong> {plan.price}</h2>
                    <p>Desconto de R$10,00 na Internet para pagamento até a data de vencimento</p>
                  </Card.Text>
                  <Button className="mais-button" onClick={() => handleShowMoreInfo(plan)}>
                    Mais informações
                  </Button> {' '}
                  <Button className="canais-button" onClick={() => handleShowChannels(plan)}>
                    Assinar
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Modal para Mais Informações */}
      <Modal show={showMoreInfo} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> <div className='internet-card'> <img src={INTERNET} alt='internet' /> {selectedPlan}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPlanData && (
            <div>
              <h4>{selectedPlanData.description}</h4>
              <p><strong>Download:</strong> {selectedPlanData.dowload}</p>
              <p><strong>Upload:</strong> {selectedPlanData.upload}</p>
              <h2><strong>Preço:</strong> {selectedPlanData.price}</h2>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='plan-span'>
            <span>
              *Promoção válida para pagamento até a data do vencimento e adesão do plano conta sem papel.*
            </span>
            <span>
              *Oferta exclusiva para clientes Gigabyte Telecom, consulte condições de disponibilidade e instalação para novos clientes.*
            </span>
          </div>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para Formulário de Contato */}
      <Modal show={showChannels} onHide={handleClose} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Formulário de Contato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm selectedPlan={selectedPlan} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Planos;
